
.list-item-building {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //padding: 0;

  //颜色的单独调整
  & .orange {
    color: #ed6c02;
  }

  & .black {
    color: black;
  }
  & .disabled{
     color: rgba(0,0,0,0.38);;
   }

  //长度调整
  & .two-story-text {
    &.longer{
      width: 7vw;
    }
    &.three-vw{
      width: 3vw;
    }
    &.four-vw{
      width: 4vw;
    }
    &.five-vw{
      width: 5vw;
    }
    &.six-vw{
      width: 6vw;
    }
    &.seven-vw{
      width: 7vw;
    }
    &.eight-vw{
      width: 8vw;
    }
    &.nine-vw{
      width: 9vw;
    }
    &.ten-vw{
      width: 10vw;
    }
  }

  //通用样式
  & .avt {
    margin: auto 0.5vw auto 0;
    width: 2.5vw;
    height: 2.5vw;
    align-self: center;
  }
  & .two-story-text {
    color: #5a6a77;
    margin: auto 0;

    & .up {
      font-size: 1.25vw;
      line-height: 1.2vw;
      margin: 0 0 0.3vw 0;
    }

    & .down {
      font-size: 0.7vw;
      min-height: 1vw;
    }
  }


  & .left {
      text-align: left;
  }

  & .right {
    text-align: right;
  }

  & .margin-left-auto{
    margin-left: auto;
  }
}


//私人交易
.list-item-building-transaction {
  & .left {
    & .two-story-text {
      max-width: 15vw;
    }

  }

  & .transaction-icon {
    width: 3vw;
    height: 3vw;
    transform: scale(1.1);
    margin: 0 0.8vw 0 0.8vw;
  }

  & .orange {
    color: #ed6c02;
  }

  & .ten-vw {
    width: 15vw;
  }

}

//仓储
.list-item-building-storage {
  margin: 0.3vw 0;

  & .left {
    & .two-story-text {
      width: 7vw;
    }
  }

  & .right {
    margin-right: 1vw;

    & .two-story-text {
      //margin-left: 1vw;
      width: 6vw;
    }
  }

  & .black {
    //color: #ed6c02;
    color: black;
  }

}

//库房下拉选框
.storage-bullet.list-item-building {
  & .left {
    & .two-story-text {
      width: 10vw;

      & .up {
        text-align: left;
      }

      & .down {
        text-align: left;
      }
    }

  }

  & .right {
    display: flex;
    text-align: right;


    & .two-story-text {
      margin-left: 1vw;
      width: 10vw;

      & .up {
        text-align: right;
      }

      & .down {
        text-align: right;
      }
    }
  }
}

//建筑招聘列表预览版
.list-item-building-Recruit-preview {
  &.disabled {
    & div:not(.MuiAvatar-root) {
      color: rgba(0, 0, 0, 0.20);
    }
  }

  & .right .two-story-text.type {
    width: 5vw;
    text-overflow: ellipsis;
  }
}

//客房的管理客人列表
.guest-bullet-root {
  & .side {
    & .avt {
      margin: auto 0.3vw auto 0;
    }
  }

  & .right .two-story-text.period {
    width: 6vw;

  }
}