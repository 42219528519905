//食谱组件的css。食谱页的css在下面
.recipe-bullet-root {
  padding: 0 1vw;
  display: flex;

  & .food-icon {
    width: 5vw;
    height: 5vw;
    border-radius: 0.3vw;
    margin-right: 1vw;
  }

  & .recipe-bullet-content {
    & .title {
      font-size: 1.25vw;
      line-height: 1.2vw;
      min-height: 1.2vw;
    }

    & .materials {
      font-size: 0.7vw;
      line-height: 1.2vw;
      color: #596a76;
      margin-bottom: 0.5vw;
    }

    & .status {
      display: flex;

      & .double-text {
        width: 4.5vw;

        & div:nth-child(1) {
          font-size: 1.3vw;
          line-height: 1.3vw;
          font-weight: 700;
        }

        & div:nth-child(2) {
          font-size: 0.6vw;
          font-weight: 700;
          color: #596a76;
        }
      }
    }
  }
}

//食谱页
.recipe-root {
  & .recipe-title {
    position: relative;
    font-size: 1.4vw;
    font-family: 'Noto Serif SC', serif;
    font-weight: 900;
    margin: 0 3vw 0 4vw;
    display: flex;
    align-items: center;
    //justify-content: space-between;
    & .my-luxury {
      font-family: 'Noto Sans SC', sans-serif;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 2vw;

      & .t {
        font-weight: 700;
        font-size: 1.4vw;
        line-height: 1.4vw;
        color: #9c7732;
      }

      & .d {
        font-weight: 500;
        font-size: 0.7vw;
        color: #5f6e7b;
      }
    }

    & .recipe-public {
      margin-left: auto;
      font-size: 0.9vw;
      font-family: 'Noto Sans SC', sans-serif;
      color: #5f6e7b;
      font-weight: 500;
    }

    & .button8 {
      position: relative;
      z-index: 5;
      top: 0.15vw;
      margin-left: 1vw;
    }
  }

  & .recipe-content {
    //margin-top: 0.8vw;
    height: calc(100vh - 20vw);
    overflow: auto;


    & .actions {
      display: flex;
      justify-content: flex-end;
      margin: 0 1vw 1vw 0;

      & div {
        margin-right: 2vw;
        font-size: 0.8vw;
        cursor: pointer;
      }

      & :nth-child(1) {
        color: #e74652;
        text-decoration: underline;
      }

      & :nth-child(2) {
        color: #5f6e7b;
        text-decoration: underline;
      }

      & .button8 {
        transform: translateY(-0.2vw);
      }
    }
  }
}

//食谱单条渲染的独立组件
.recipe-bullet {
  margin: 0.5vw 0 0 0;
  //padding: 0.5vw 0 0.5vw 0;
  & .MuiListItemButton-root {
    display: block;
    margin-top: 0.4vw;
    margin-bottom: 0.4vw;
  }

  & .recipe-bullet-root {
    margin-bottom: 0.8vw;
  }

  & .cost {
    margin: 0 0 0 7vw;
    font-size: 0.8vw;
    color: #5f6e7b;
  }

}