html {
  //.App {

  //所有图片禁用拖动
  & img {
    -webkit-user-drag: none;
  }

  //所有想要禁用to的就给这个class
  & .disabled-link {
    pointer-events: none;
  }


  //颜色系列
  & .energy {
    color: #46b4e6 !important;
  }

  & .happiness {
    color: #ff8842 !important;
  }

  & .health {
    color: #73b93c !important;
  }

  & .hungry {
    color: #e74652 !important;
  }

  & .luxury {
    //个人页面来的的黄色
    color: #b38460 !important;
  }

  & .black {
    color: black !important;
  }


  //按钮,8号
  & .button8 {
    font-size: 0.8vw;
    line-height: 1.1vw;
    font-family: 'Noto Sans SC', sans-serif;
    font-weight: 300;
    width: 5vw;
    height: 1.2vw;
    text-align: center;
    border-radius: 0.2vw;
    border: 0.15vw solid white;
    outline: 0.15vw solid #fd7700;
    transition: box-shadow 200ms;
    box-shadow: 0 0 0.6vw white;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      box-shadow: 0 0 0.6vw #fd7700;
    }

    &.white-button {
      color: #fd7700;
      background-color: white;
      border-color: #fd7700;
      outline-color: white;
    }

    &.orange-button {
      color: white;
      background-color: #fd7700;
    }

    &.disabled {
      color: white;
      background-color: lightgray;
      border-color: white;
      outline-color: lightgray;
      cursor: auto;
      pointer-events: none;

    }
  }

  //禁用数字输入框的上下箭头
  & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button {
    display: none
  }

  & input[type=number] {
    MozAppearance: textfield
  }


}