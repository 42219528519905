.building-info-root {
  margin: 0 2vw 0 3vw;

  & .add-photo-avt-root {
    margin-bottom: 1vw;
  }

  & .MuiTextField-root {
    margin-bottom: 1vw;
  }

  & .flex-end {
    display: flex;
    margin-right: 1vw;
    justify-content: flex-end;

    & .apply-btn {
      max-height: 2vw;
      min-height: 2vw;
      max-width: 6vw;
      min-width: 6vw;
      font-size: 0.8vw;
    }

  }
}