.work {
  & .work-title-line {
    width: 100%;
    position: relative;

    & .work-title {
      margin: 1vw auto 0.5vw;
      font-size: 1.4vw;
      font-family: 'Noto Serif SC', serif;
      font-weight: 900;
      text-align: center;
    }

    & .method-info {
      width: 19vw;
      padding-bottom: 0.4vw;
      text-align: center;
      color: #5a6a77;
      font-size: 0.6vw;
      margin: 0 auto ;
      //border-left: #5a6a77 solid 0.1vw;
      //border-right: #5a6a77 solid 0.1vw;
      border-left: 0.1vw solid rgba(0, 0, 0, 0.25);
      border-right: 0.1vw solid rgba(0, 0, 0, 0.25);
    }

    & .WMethod {
      width: 19vw;
      height: 3.7vw;
      margin: 0 auto;
      //border: #5a6a77 solid 0.1vw;
      border: 0.1vw solid rgba(0, 0, 0, 0.25);
      border-bottom-left-radius: 0.2vw;
      border-bottom-right-radius: 0.2vw;
      display: flex;
      justify-content: center;
      padding-top: 0.2vw;
    }

    & .WMethod-text {
      text-align: center;
      color: #5a6a77;
      font-size: 0.6vw;
      margin-top: 0.3vw;
    }

    & .second-info {
      //display: inline-block;
      width: 70%;
      margin-left: 1vw;

      & .work-progress {
        display: inline-block;
        margin: 0 0.5vw 0 0.2vw;
      }

      & .work-text {
        display: inline-block;

      }
    }

  }


  & .work-status {
    text-align: center;
    width: 100%;
    height: 100%;
  }

  & .work {
    width: 100%;
    height: 100%;
  }

  & .grid-container {
    height: calc(100vh - 22vw);
    padding: 0.5vw 0;
    position: relative;
    top: 2vw;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    & .MuiGrid-root.MuiGrid-container{
      padding-bottom: 20vw;
    }
  }

  & .grid-container::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}