
.restaurant-menu-root {
  & .restaurant-menu-title {
    position: relative;
    font-size: 1.4vw;
    font-family: 'Noto Serif SC', serif;
    font-weight: 900;
    margin: 0 3vw 0 4vw;
    display: flex;
    align-items: center;

    & .my-luxury {
      font-family: 'Noto Sans SC', sans-serif;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 2vw;

      & .t {
        font-weight: 700;
        font-size: 1.4vw;
        line-height: 1.4vw;
        color: #9c7732;
      }

      & .d {
        font-weight: 500;
        font-size: 0.7vw;
        color: #5f6e7b;
      }
    }

    & .recipe-public {
      margin-left: auto;
      font-size: 0.9vw;
      font-family: 'Noto Sans SC', sans-serif;
      color: #5f6e7b;
      font-weight: 500;
    }

    & .button8 {
      position: relative;
      z-index: 5;
      top: 0.15vw;
      margin-left: auto;
    }
  }

  & .restaurant-menu-content {
    height: calc(100vh - 20vw);
    overflow: auto;

    & .recipe {
      margin-top: 1.5vw;

      & .MuiDivider-root {
        margin-top: 1.5vw;
      }

      & .actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0 2vw 1vw 6.5vw;

        //& div {
        //  margin-right: 2vw;
        //  font-size: 0.8vw;
        //}
        & .hints {
          color: #5f6e7b;

          & b {
            color: black;
          }

          & .hint {
            font-size: 0.7vw;
          }
        }

        & .t {
          margin: 0 0.5vw 0 auto;
          font-size: 0.8vw;
          color: grey;
        }

        & .button8 {
          position: relative;
          display: flex;
          align-items: center;

          &.white-button {
            width: 9vw;
            padding-left: 1vw;
            //text-align: left;

            & span {
              display: flex;
              align-items: center;

              & .icon-image-root {
                outline: 0.3vw white solid;
              }
            }
          }

          &.orange-button {
            width: 10vw;
            justify-content: center;
          }
        }
      }

    }
  }
}