
//.p-signup.captcha{
//     align-content:center; justify-content:center
//}
.p-signup .captcha{
    //background-color: #75ba38;
    padding: 10px 10px 37px 10px ;
    border-radius: 3px;
    border: 1px solid grey;
}
.p-signup .HCaptcha-texT{
    font-size: 12px;
    text-align: center;
}
.login-signUp-jump,.login-forgetPW{
    text-align: center;
color: #808080;
    margin-top: 10px;
}

html {
    background-color: #202125;
}

.p-signup {
    flex-direction: row;
    display: flex;
    background-color: #202125;
    color: #808080;
    height: max(100%, 100vh);
    font-size: 15px;

    & .title{
        text-align: center;
        font-family: 'Noto Serif SC', serif;
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 20px;
    }
    & .left-container {
        flex-direction: row;
        display: flex ;
        flex: 1;
        justify-content: center;
        align-items: center;
        padding-top: 160px;

        & .left {
            width: 308px;
            height: 309px;
            margin: 230px 50px;
            align-self: start;

            & img {
                width: 100%;
                height: 100%;
            }
        }
    }

    & .signup-container {
        flex: 0 0 300px 0;
        flex-direction: column;

        & .logo {
            //display: flex;
            flex-grow: 1;
            width: 240px;
            margin: 80px auto 0 auto;

            & img {
                width: 100%;
                height: 100%;
                margin-bottom: 10px;
            }
            & iframe {
                position: relative;
                left: -150px;
            }
        }

        & .signup-form {
            flex-grow: 1;
            //display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            //margin-bottom: 40px;

            & .MuiFormControl-root {
                margin-bottom: 10px;
                height: 50px;
            }

        }

        & .form-actions {
            flex-grow:1;
            margin-top: 30px;
            position: relative;
            //display: flex;
            //justify-content: center;

            & .submit-btn {

                font-family: 'Noto Serif SC', serif;
                font-weight: 900;
                color: #ed6c02;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: 35px;
                line-height: 25px;
                letter-spacing: 6px;
                width: 197px;
                height: 65px;
                background-repeat: no-repeat;
                background-size: cover;
                background-color:#202125 ;
                background-image: url("./assets/login/button_black.webp");
                transition-duration: 200ms;
                outline: none;
                border: 1px solid grey;
                border-radius: 3px ;
            }
            & .submit-btn:hover{
                border:none;
                color: #202125;
                background-image: url("./assets/login/big-button-background.webp");
                box-shadow:  0.2px 0 10px 0  #ed6c02;
            }
        }
    }

    & .right-container {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        padding-top: 160px;

        & .texts {
            width: 300px;
            margin: 220px 50px;
            align-self: start;

            & p {
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.06px;

                & strong {
                    color: #bfbfbf;
                    font-weight: 500;
                }
            }
        }
    }
}

.p-signup .alert {
    margin-top: 20px;
    color: red;
    text-align: center;
    font-size: 15px;
}
