.lease-contract-root {
  margin: 0 0 0 2.3vw;

  & .MuiCheckbox-root {
    position: relative;
    //top: -0.7vw;
    width: 1.2vw;
    height: 1.2vw;
    margin-right: -0.2vw;
  }

  & .lease-contract-bullets {
    margin: 1vw 0;

    & .lease-contract-bullet:nth-child(1) {
      margin-bottom: 1vw;
      border: solid 0.2vw #fd7700;
    &::after{
      position: absolute;
      bottom: -1vw;
      left: -0.2vw;
      content: "只有第一条租房合同在租房市场显示";
      font-size: 0.7vw;
      color: white;
      background-color: #ed6c02;
      width: fit-content;
      padding: 0 0.5vw;
      margin-top: -0.7vw;
    }
    }

    & .lease-contract-bullet {
      display: flex;
      position: relative;
      align-items: center;
      //margin-bottom: 1vw;
      margin: 0.6vw 0;
      padding: 0.3vw 0 0.3vw 0.3vw;
      border-radius: 0.4vw;

      & .MuiButton-root {
        max-height: 2vw;
        min-height: 2vw;
        max-width: 3vw;
        min-width: 3vw;
        font-size: 0.8vw;
        color: #ed6c02;
        border-color: #ed6c02;
        background-color: white;
      }

      & .no {
        font-size: 1.2vw;
        font-weight: 900;
        color: rgba(0, 0, 0, 0.25);
        margin-right: 0.3vw;
        width: 0.8vw;
        overflow: visible;
      }

      & .MuiFormControl-root {
        margin-right: 0.4vw;
        width: 6vw;
        max-width: 6vw;
      }


      & .allow {
        font-size: 0.8vw;
        color: #5f6e7b;
      }
    }
  }

  & .new {
    max-height: 2vw;
    min-height: 2vw;
    //max-width: 7vw;
    min-width: 7vw;
    font-size: 0.8vw;
    //line-height: 1.5vw;
    color: #ed6c02;
    border-color: #ed6c02;
    background-color: white;
    margin-right: 0.5vw;
  }

  & .row {
    width: 90%;
    display: flex;
    justify-content: flex-end;

    & .new {
      max-width: 6vw;
      min-width: 6vw;
    }

    & .accept {
      max-height: 2vw;
      min-height: 2vw;
      max-width: 6vw;
      min-width: 6vw;
      font-size: 0.8vw;
      color: white;
      background-color: #ed6c02;
    }
  }
}