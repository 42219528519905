.agent-item-agent {
  display: flex;
  position: absolute;
  height: 90%;
  max-width: 3vw;
  top: 50%;
  transform: translateY(-50%);
  border-top-left-radius: 0.2vw;
  border-bottom-left-radius: 0.2vw;
  background-image: url("assets/texture.webp");
  background-size: 23vw;
  color: white;
  pointer-events: none;
  mask-image: linear-gradient(to left, transparent 0vw, black 1.5vw);
  transition: width 1000ms;
  -webkit-transition: max-width ease-out 100ms;

  &:hover {
    max-width: 27vw;
    //max-width: initial;

  }


  & .list-item-building {
    min-width: 47vw;
    justify-content: flex-start;

    & .two-story-text {
      & .up, & .down {
        color: white;
      }

      &.tax {
        font-weight: 700;
      }
    }
  }

  & .MuiSvgIcon-root {
    padding: 0 0.3vw;
    pointer-events: auto;
    height: 100%;
  }

  & .agent-description {
    font-size: 0.7vw;
    text-align: center;
    margin: 0 3vw 0 2vw;
    max-width: 15vw;
  }
}