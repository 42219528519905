.icon-image-root {
  align-self: center;
  //display: flex;
  display: inline-block;
  position: relative;

  & .icon-image {
    align-self: center;
    transform: scale(1.1);

    &.icon-image-item {
      border-radius: 0.3vw;
    }
  }

  &.item-image-root {
    & .stars {
      line-height: 20%;
      position: absolute;
      display: flex;
      top: -7%;
      right: 10%;
      justify-content: flex-end;
      justify-self: flex-end;

      & .star {
        -webkit-text-stroke: 0.04vw white;
        color: #ed6c02;
        transform: rotate(15deg);
      }
    }
  }

  &.skill-image-root {
    & .progress-container {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
      //background-color: white;
      outline: 0.1vw solid #9c7732;

      & .half-container {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;

        & .half-progress {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
    }

    & .icon-image {
      transform: scale(0.9);
      border-radius: 50%;
      overflow: hidden;
    }
    & .icon-image-text{
      position: absolute;
      bottom: -3%;
      left: 50%;
      transform: translateX(-50%) ;
      padding: 0 0.2vw;
      font-size: 0.5vw;
      line-height: 0.6vw;
      white-space: nowrap;
      color: #674c21;
      background-color: white;
      border-radius: 0.1vw;
      outline: 0.1vw solid #9c7732;
    }
  }
}
