.App {
  & .MuiListItemButton-root {
    padding: 0.8vw 1vw;
  }

  /*分割线*/
  & .MuiDivider-root {
    /*border-width: 0.1vw;*/
    /*border-top-width: 0.1vw;*/
    /*border-right-width: 0.1vw;*/
    border-bottom-width: 0.1vw;
    /*border-left-width: 0.1vw;*/
    /*margin: 0 0;*/

    &.MuiDivider-middle {
      margin: 0 1.6vw;
    }

    /*竖直分割线*/
    &.MuiDivider-vertical {
      /*border-width: 0.1vw;*/
      /*border-top-width: 0.1vw;*/
      border-right-width: 0.1vw;
      border-bottom-width: 0;
      /*border-left-width: 0.1vw;*/
      margin: 0;
      //竖直+中间
      &.MuiDivider-middle {
        height: calc(100% - 1.6vw);
        margin: 0.8vw 0;
      }
    }

  }


  & .MuiButton-outlined, & .MuiButton-contained {
    border-width: 0.1vw;
    border-radius: 0.3vw;
  }

  //按钮的px的padding
  & .MuiButtonBase-root.MuiButton-root {
    padding: 0.5vw 1.5vw;
    min-width: 6.4vw;
  }

  & .MuiButtonBase-root.MuiButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    /*opacity:0.38;*/
    border-color: rgba(0, 0, 0, 0.26);
    border-width: 0.1vw;
  }

  & .MuiList-root {
    padding-bottom: 0.8vw;
    padding-top: 0.8vw;
  }

  /*输入框描边*/
  & .MuiOutlinedInput-root {
    border-radius: 0.2vw;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-width: 0.1vw;
  }


  & .Mui-focused {
    border-width: 0.1vw;
  }

  & .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper {
    border-radius: 0.4vw;
  }


  & .MuiOutlinedInput-notchedOutline {
    /*border-width: 0.1vw;*/
  }

  /*可能得写到最里面，写这里没用*/
  & .Mui-focused.MuiOutlinedInput-notchedOutline {
    border-width: 0.2vw
  }

  /*选择的下拉框，下拉选项*/
  & .MuiButtonBase-root.MuiMenuItem-root {
    padding: 0.6vw 1.6vw;
    min-height: 3vw;
    /*line-height: 1vw;*/
  }

  /*avatar头像*/
  & .MuiAvatar-root {
  }

  /*圆角的头像*/
  & .MuiAvatar-rounded {
    border-radius: 0.4vw;
  }

  /*复选框*/
  & .MuiButtonBase-root.MuiCheckbox-root {
    padding: 0.9vw;
  }

  /*单选框*/
  & .MuiButtonBase-root.MuiRadio-root {
    padding: 0.9vw;
  }

  //图标按钮icon button
  & .MuiButtonBase-root.MuiIconButton-root {
    padding: 0.4vw;
  }

  /*switch滑动切换的那个*/
  & .MuiSwitch-root {
    padding: 1.2vw;
    height: 3.8vw;
    width: 5.8vw;
  }

  & .MuiSwitch-switchBase {
    margin: 0;
    padding: 0.9vw;
    /*transform: translateX(0.6vw);*/
  }

  & .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(2vw);
  }

  & .MuiSwitch-switchBase .MuiSwitch-thumb {
    width: 2vw;
    height: 2vw;
  }

  & .MuiSwitch-track {
    border-radius: 0.7vw;
  }


  /*MuiInputBase*/
  & .MuiInputBase-input {
    padding: 0.65vw 1vw;
    border-radius: 0.4vw;

    //MuiInputBase  small时候的padding
    &.MuiInputBase-sizeSmall, &.MuiInput-inputSizeSmall, &.MuiInputBase-inputSizeSmall {
      padding: 0.2vw 1vw;
      font-size: 0.8vw;

      &:not(.MuiSelect-select) {
        padding-top: 0.35vw;
        padding-bottom: 0.35vw;
      }
    }
  }

  /*input的小标签label*/
  & .MuiFormLabel-root.MuiInputLabel-root {
    transform: translate(1.2vw, 0.7vw) scale(1);

    &.MuiInputLabel-sizeSmall {
      transform: translate(1.2vw, 0.4vw) scale(1);
      font-size: 0.8vw;
    }

    &.Mui-focused, &.MuiFormLabel-filled {
      transform: translate(0.9vw, -0.5vw) scale(0.75);

      &.MuiInputLabel-sizeSmall {
        transform: translate(0.9vw, -0.5vw) scale(0.94);
      }
    }
  }

  //选择的小三角
  & .MuiSvgIcon-root.MuiSelect-icon {
    right: 0.7vw;
  }

  & .MuiOutlinedInput-notchedOutline {
    top: -0.5vw;
    padding: 0 0.8vw;
  }

  /*select的下拉框??*/
  & .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper {
    max-height: calc(100% - 3.2vw);
    max-width: calc(100% - 3.2vw);
    min-height: 1.6vw;
    min-width: 1.6vw;
    left: 9.4vw;
  }

  /*input 和select的label*/
  /*选中状态好像是.Mui-focused*/
  & .MuiFormLabel-root.MuiInputLabel-root {
    max-width: calc(133% - 2.4vw)
  }


  /*小标签留白*/
  & .MuiInputBase-root legend {
    height: 1.1vw;
  }

  /*小标签留白里面算尺寸的*/
  & .MuiInputBase-root legend span {
    padding-left: 0;
    padding-right: 0;
  }

  /*外框，选中变色那个*/
  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 0.1vw !important;
  }

  //tab的总的组
  & .MuiTabs-root {
    min-height: 3.2vw;
  }

  //tab按钮
  & .MuiButtonBase-root.MuiTab-root {
    min-width: 6vw;
    min-height: 3.2vw;
    padding: 0.8vw 1.2vw;
  }

  //tab的那个指示条
  & .MuiTabs-indicator {
    height: 0.2vw;
  }
  //tabs的panel，妈的你panel 搞个style干嘛了
  & .MuiTabPanel-root {
    padding: 0;
  }
}

//-------------------------.app的结束-------------------------


////mui popover+paper的那个超级厚阴影
//.MuiPopover-paper {
//  //& .MuiPaper-root.MuiPopover-paper {
//  box-shadow: 0 0.5vw 1vw -0.3vw rgba(0, 0, 0, 0.05);
//}

