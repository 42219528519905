.agent-info-root {
  margin-left: 0.5vw;
  margin-bottom: 2vw;

  & .heavy {
    font-weight: 700;
    margin-top: 1vw;
    //color: black;
  }

  & .preview {
    border-radius: 0.3vw;
    border: solid 0.15vw rgba(0, 0, 0, 0.05);
    margin: 0 3vw 1vw 3vw;
    padding: 0.3vw 1.5vw 0.3vw 1vw;

    & .list-item-building-Recruit.disabled {
    }
  }

  & .flex {
    display: flex;
    align-content: center;
    margin-left: 2vw;
    //不在招聘
    & > div {
      padding-top: 0.75vw;
      font-size: 1.6vw;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.20);
      transition: color 200ms;
    }

    //  在招聘
    & .active {
      color: #fd7700;
      //text-shadow: 0 0 0.3vw rgba(93,42,1,0.3) ;
      //-webkit-text-stroke: 0.05vw white;
    }
  }

  & .change {
    margin: 1.3vw 2.7vw;
    display: flex;
    align-content: center;
    align-items: center;

    & .quantity .MuiInputBase-input.MuiOutlinedInput-input {
      width: 5.5vw;
    }
    & .MuiCheckbox-root{
      margin-left: 3vw;
    }
    & .same-district-only{
      margin: 0 3vw 0 0 ;
      font-weight: 700;
    }
    & .apply-job-btn {
      max-height: 2vw;
      min-height: 2vw;
      max-width: 6vw;
      min-width: 6vw;
      font-size: 0.8vw;
      color: white;
      background-color: #ed6c02;
      margin-left: 1vw;
    }
  }

}