.manage-workers-root {
  & .manage-workers-content {
    margin: 0 2vw;
    & .worker {
      display: flex;
      align-items: center;
      margin-bottom: 0.8vw;

      & .options {
        max-height: 2vw;
        min-height: 2vw;
        max-width: 3vw;
        min-width: 3vw;
        font-size: 0.8vw;
        color: #ed6c02;
        border-color: #ed6c02;
        background-color: white;
      }
    }
  }
}
//工人列表
.worker-bullet-root{
  & .side {
    & .avt {
      margin: auto 0.3vw auto 0;
    }
  }
  & .right .two-story-text.period{
    width: 6vw;

  }
}