.pick-item-list-root {
  width: 23vw;
  height: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .title {
    text-align: center;
    font-size: 0.8vw;
    color: #5f6e7b;
    margin: 0.1vw 0;
  }

  & .list {
    & .row {
      display: flex;
      justify-content: center;
      margin: 0.7vw 0;
      align-items: center;

      & .name {
        text-align: center;
        width: 5vw;
        font-weight: 700;
      }

      & .pointer {
        cursor: pointer;
      }
    }
  }
}