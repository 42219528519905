.method-bullet-component {
  color: rgba(0, 0, 0, 0.25);
  height: 3.1vw;
  border-radius: 0.3vw;
  border: solid 0.2vw rgba(0, 0, 0, 0.15);
  display: flex;
  position: relative;

  & img {
    margin-left: 0.2vw;
  }

  & svg {
    display: block;
    margin: auto;
    font-size: 1.9vw;
  }

  & .sub {
    max-width: 0.9vw;
    font-size: 0.6vw;
    line-height: 0.7vw;
    word-break: normal;
    margin: 0.9vw 0 0 0.8vw;
  }

  & .no {
    position: absolute;
    left: 1.5vw;
    top: 1vw;
    font-size: 0.8vw;
  }

  & .m {
    width: 16vw;
    display: flex;
    justify-content: center;

    & .to {
      width: 3vw;

      & .arrow {
        color: rgba(0, 0, 0, 0.15);
        max-height: 1.5vw;
        height: 1.5vw;
        margin: 0.2vw auto 0 auto;
        position: relative;
        display: flex;

        & .arrow-icon {
          transform: scaleX(1.3);
          //position: absolute;
        }
      }

      & .skill {
        font-size: 0.8vw;
        text-align: center;
        white-space: nowrap;
      }
    }
  }
  & .m-cons{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 1.5vw;
    & .cons-line{
      display: flex;
      & .text-me{
        width: 5.2vw;
        font-size: 0.7vw;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.25);
        &.active{
          color: #064562;
        }
      }
    }
  }
}