.districtRoot .MuiListItemButton-root {
  padding: 0.1vw 1vw;
}

.districtRoot {
  height: calc(100vh - 5vw);
  position: relative;
  padding-left: 2vw;
  display: flex;
}

.districtRoot .left-container {
  flex: 1;
  background-image: url("assets/district-bg.webp");
  background-size: 100% auto;
  background-repeat: no-repeat;
  border-top-left-radius: 1vw;
  box-shadow: inset -1vw 0 0.6vw -0.6vw rgba(0, 0, 0, 0.15);
}

.districtRoot .right-container {
  flex: 3.68;
  position: relative;
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.districtRoot .right-container::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}


.districtRoot .left-menu-container {
  margin-top: 2vw;
  position: relative;
  //要z-index所以不能和外面left-container合
  z-index: 5;
  margin-left: 2vw;
}
.districtRoot .MuiSelect-outlined {
  padding: 0.5vw 1vw !important;
}
.districtRoot .goods-list-container {
  margin: auto 1vw;
}

