.SellModal {
  //width: 50vw;
  min-height: 30vw;
  background-color: white;
  border-radius: 0.5vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1vw;

  &.modal-content-root.width-override {
    width: 48vw;
  }
  & .content-container{
    height: 28vw;
    overflow-y: auto;
  }

  & .title-line {
    display: flex;

    & .close {
      font-size: 4vw;
      cursor: pointer;
    }

    & .r {
      & .title {
        font-size: 1.5vw;
        color: black;
        font-family: 'Noto Serif SC', serif;
        font-weight: 900;
      }

    }
  }

  & .sub-title {
    margin: 1vw 0 0.5vw 1vw;
    text-align: center;
  }

  & .description {
    font-size: 0.8vw;
    color: #5f6e7b;
  }

  & .building-line {
    width: 42vw;
    margin: 0 auto;
  }

  & .list-item-building-storage {
    width: 90%;
    margin-left: auto;
    margin-right: 0;
    margin-bottom: 1vw;

    & .starter-text {
      margin-right: 0.5vw;
      font-size: 0.8vw;
      font-weight: 700;
    }

    & .side {
      display: flex;
      align-items: center;
    }

    & .provide-item {
      display: flex;
      align-items: center;
      width: 17vw;

      & .MuiTextField-root {
        width: 13vw;
      }
    }

    & .required-item {
      text-align: left;
      margin-left: 0;
      width: 15vw;
    }

    & .price {
      width: 13vw;
    }

    & .emphasis {
      color: #ed6c02;
      font-weight: 700;
    }
  }

  & .agents-container {
    width: 80%;
    margin: 0 auto;
    padding: 0.5vw;
    border-radius: 0.2vw;
    border: solid 0.1vw #5f6e7b;

    & .agent-container {
      margin: 0.25vw 0;
    }
  }

  & .buttons {
    display: flex;
    width: 100%;
    padding: 1vw 0;
    justify-content: space-around;
  }
  & .status-bar2x2{
    position: relative;
    left: 12vw;
    margin-top: 1vw;
  }
}
