.traits-root {
  & .trait-root {
    margin: 0.4vw 0 ;
    & .trait_content {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1vw 1vw;

      & .trait_icon {
        width: 4.5vw;
        height: 4.5vw;
      }

      & .trait_progress_container {
        position: relative;
        margin: 0 1vw;


        --border: solid 0.2vw rgba(0, 0, 0, 0.6);

        & .bar {
          position: relative;
          //margin: 0 0.7vw;
          width: 21vw;
          height: 1.2vw;
          background-color: #e0d4c3;
          overflow: hidden;
          border-left: var(--border);
          border-right: var(--border);


          & .inner_bar {
            width: 100%;
            height: 100%;
            background-color: #9c7732;
            position: relative;
            transition: left 300ms;

          }

          & .text {
            position: absolute;
            top: 0;
            line-height: 1.2vw;
            font-weight: 700;
            padding: 0 0.5vw;
          }
        }

        & .dashed {
          position: absolute;
          height: 2vw;
          width: 0.5vw;
          left: 50%;
          top: 30%;
          border-left: 0.1vw dashed #064562;
        }

        & .indicator {
          position: absolute;
          height: 1.8vw;
          width: 0.1vw;
          top: 30%;
          border-left: 0.4vw solid #064562;
          transition: left 300ms;

        }

        & .progress_text {
          text-align: center;
          display: flex;
          margin: 0.3vw -0.7vw;
          justify-content: space-between;
          color: #5f6e7b;
          font-family: 'Noto Sans SC', sans-serif;
          font-size: 0.8vw;

          & > div {
            width: 2vw;
          }
        }
      }

    }

    & .trait_description {
      width: 100%;
      text-align: center;
      color: #5f6e7b;
      font-size: 0.8vw;

    }
  }
}