.settings-root {
  position: relative;
  display: flex;
  height: calc(100vh - 5vw);
  padding-left: 2vw;

  & .title {
    font-size: 1.5vw;
    font-family: 'Noto Serif SC', serif;
    font-weight: 900;
    text-align: left;
    margin: 1vw 2vw 0 2vw;
  }

  & .MuiDivider-middle.title-divider {
    margin-top: 3vw;
    margin-bottom: 0.5vw;
  }

  & .left-container {
    flex: 1;
    height: 100%;

    & .setting-tabs {
      & .setting-tab {
        color: #5f6e7b;
        font-family: 'Noto Sans SC', sans-serif;
        padding-left: 3vw;
        border-right: 0.3vw solid;
      }
    }
  }

  & .right-container {
    flex: 2;
    height: 100%;

    & .right-content {
      max-height: calc(100vh - 10vw);
      overflow-y: auto;

      & .content-bullet {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 2vw;

        //右版面设置标题
        & .set-ttl {
          color: #5f6e7b;
          font-family: 'Noto Sans SC', sans-serif;
        }

        //右版面设置内容
        & .set-content {

        }

        //换头像
        --light-blue: rgba(95, 110, 123, 0.3);

        & .pfp-avt {
          position: relative;
          display: flex;
          align-items: center;
          border-radius: 0.4vw;
          height: 7vw;
          border: 0.2vw solid var(--light-blue);
          overflow: hidden;

          & .r {
            color: #5f6e7b;
            width: 13vw;
            margin-left: 1vw;

            & :nth-child(1) {
              font-size: 2vw;
              line-height: 2vw;
              font-family: 'Noto Serif SC', serif;
              font-weight: 900;
              text-align: left;
              color: var(--light-blue);
            }

            & :nth-child(2) {
              color: var(--light-blue);
              text-align: left;
              font-size: 0.7vw;
              font-weight: 700;
            }
          }

          & .stick {
            position: absolute;
            width: 5vw;
            height: 0.7vw;
            background-color: var(--light-blue);
          }
        }
      }

      //制作人员表底层
      & .credits {
        width: 100%;
        padding-top: 2vw;
        padding-bottom: 10vw;

        & .store_img {
          display: block;
          margin: 1vw auto;
          width: 15vw;
          height: 8vw;
        }

        & .ttl {
          text-align: center;
          font-weight: 700;
          margin-bottom: 0.5vw;
          color: #5f6e7b;
        }

        & .block {
          display: flex;

          & .l {
         font-size: 0.8vw;
            flex: 1;
            text-align: right;

          }

          & .r {
            flex: 1;
            font-weight: 700;
            text-align: left;
            margin-left: 0.5vw;

          }
        }

      }
    }
  }
}