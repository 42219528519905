.storage .MuiListItemButton-root {
  padding: 0.1vw 1vw;
}

.storage {

  & .storage-title-line {
    width: 100%;
    position: relative;
    left: 4vw;

    & .storage-title {
      display: inline-block;
      max-width: 5vw;
      font-size: 1.4vw;
      bottom: 0;
      /*height: 4vw;*/
      font-family: 'Noto Serif SC', serif;
      font-weight: 900;
    }

    & .storage-progress {
      position: relative;
      display: inline-block;
      transform: translateY(-0.2vw);
      margin-left: 1vw;


    }

    & .storage-text {
      position: relative;
      display: inline-block;
      color: #596a76;
      font-size: 0.8vw;
      margin-left: 1vw;
      bottom: 0.2vw;
    }
  }
  //
  //& .work-status {
  //  text-align: center;
  //  width: 100%;
  //  height: 100%;
  //}
  //
  //& .work {
  //  height: 50vh;
  //}

  & .grid-container {
    //position: relative;
    //top: 1.8vw;
    margin-top: 1.8vw;
    display: flex;
    justify-content: space-between;

    & .buttons {
      margin-left: 3.8vw;
      display: flex;
      //justify-content: right;
      & * {
      //& > div {
        min-width: 5vw;
        max-width: 5vw;
        max-height: 1.2vw;
        margin-right: 1vw;
        cursor: pointer;
        //border: #ed6c02 solid 0.1vw;
        border-radius: 0.2vw;
        color: #ed6c02;
        border-color: #ed6c02;
        font-size: 0.8vw;
        text-align: center;
        padding: 0.2vw 0.4vw;
      }

      & > div:hover {
        background-color: rgba(237, 108, 2, 0.1);
      }
    }

    & .fake-filter {
      //margin-bottom: 0.2vw;
      margin-right: 2vw;
      display: flex;
      justify-content: right;

      & > div {
        width: 7vw;
        color: #596a76;
        font-size: 0.8vw;
        text-align: right;

      }
    }
  }
  & .goods-list-container{
    height: calc(100vh - 22vw);
    overflow: auto;
    & .MuiList-root{
      //overflow: auto;
      & .list-item-building-storage{
        min-height: 3vw;
      }
    }
  }
}
