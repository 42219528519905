.building-in-list-root {
  display: flex;
  position: relative;
  & .img-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2vw 0;
    border-top-right-radius: 0.2vw;
    border-bottom-right-radius: 0.2vw;
    overflow: hidden;
    mask-image: linear-gradient(to right, transparent 0, black 30%);
    & img{
      position: relative;
      left: 0.5vw;
      height: 6vw;
      object-fit: cover;
    }

  }
  & .stars {
    //line-height: 100%;
    position: absolute;
    display: flex;
    top: -40%;
    right: 10%;
    justify-content: flex-end;
    justify-self: flex-end;

    & .star {
      -webkit-text-stroke: 0.04vw white;
      color: #ed6c02;
      transform: rotate(15deg);
    }
  }
}