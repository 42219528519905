.edit-recipe-root {

  & .edit-recipe-title {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 1.4vw;
    font-family: 'Noto Serif SC', serif;
    font-weight: 900;
    margin: -0.3vw 3vw 0 1.8vw;
  }

  & .edit-recipe-content {
    overflow-y: auto;
    height: calc(100vh - 19vw);
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    &::-webkit-scrollbar {
      display: none;
    }

    & .sub-title {
      color: #596a76;
      margin-left: 2.5vw;
      margin-top: 1.5vw;
      font-size: 1.4vw;
      font-family: 'Noto Serif SC', serif;
      font-weight: 900;

      & .MuiSvgIcon-root {
        position: relative;
        top: 0.25vw
      }

      &.cooking-methods-sub-title {
        margin-bottom: -1vw;
      }
    }

    & .read-only {
      margin: 0 3vw 1vw auto;
      padding: 0.2vw 0.5vw;
      width: 5vw;
      background-color: rgba(239, 239, 239, 1);
      color: rgba(0, 0, 0, 0.3);
      font-size: 0.7vw;
      line-height: 0.7vw;
      text-align: center;
      font-weight: 700;
      //font-family: 'Noto Serif SC', serif;
    }

    & .recipe-image-line {
      display: flex;
      align-items: center;
      margin: 1vw 0 1vw 3vw;

      & .food-icon {
        width: 4.8vw;
        height: 4.8vw;
        border-radius: 0.3vw;
        margin-right: 1vw;
      }

      & .add-photo-avt-root {
        margin-right: 1vw;
      }

      & .edit-name-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 4.5vw;

        & .name {
          display: flex;
          align-items: center;

          & .MuiSvgIcon-root {
            margin-right: 0.3vw;
          }

          & .MuiTextField-root {
            width: 24vw;
          }
        }

        & .description {
          width: 27vw;
          color: #5f6e7b;
          font-size: 0.7vw;
        }

      }
    }

    & .cooking-methods {
      display: flex;
      position: relative;
      width: 100%;
      height: 15vw;
      background-image: url('./assets/1.webp');
      background-size: 105%;
      background-repeat: no-repeat;
      background-position: 0 center;
      margin-bottom: 3vw;
      align-items: center;

      & .choices {
        flex: 1;
        position: relative;
        margin-left: 2vw;
        max-height: 10vw;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start;

        & > div:nth-child(6n), & > div:nth-child(6n-1), & > div:nth-child(6n-2), {
          top: 1.25vw;
        }
      }

      & .sticker {
        position: relative;
        min-width: 14vw;
        max-width: 14vw;
        height: 15vw;
        margin-right: 3vw;
        background-image: url("./assets/sticker1.webp");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        flex-direction: column;
        justify-items: center;
        text-align: center;
        transform: scale(1.15) rotate(3deg);

        & > div {
          position: relative;
        }

        & img {
          position: absolute;
          pointer-events: none;
          left: -2vw;
          top: -2vw;
          width: 90%;
          mask-size: 110%;
          mask-position: 2vw 2vw;
          mask-repeat: no-repeat;
          mask-image: url("./assets/sticker1.webp");
          //filter: grayscale(1) brightness(0);
        }

        & .using {
          font-size: 0.45vw;
          color: #5f6e7b;
          margin-top: 0.2vw;
          margin-bottom: 0.95vw;
        }

        & .topic {
          font-family: 'Ma Shan Zheng', cursive;
          font-size: 3vw;
          margin-bottom: 0.5vw;
        }

        & > .description {
          font-size: 0.6vw;
          margin: 0 0.8vw 0.3vw 0.8vw;

        }

        & .buff {
          background-color: #feffd4;
          border-radius: 0.2vw;
          box-shadow: 0 0.1vw 0.2vw rgba(0, 0, 0, 0.4);

        }

        & .buff1 {
          margin: 0 0.6vw;
          font-size: 0.6vw;
          height: 3vw;
          display: flex;
          align-items: center;

          & .preview {
            flex: 1;
          }

          & .description {
            flex: 4;
            margin: 0 1vw 0 0;
            text-align: left;
          }

        }

        & .buff2-3 {
          display: flex;
          font-size: 0.6vw;
          position: relative;
          text-align: left;

          & .MuiSvgIcon-root {
            position: absolute;
            color: white;
            left: -0.5vw;
            top: 0;
            font-size: 1.7vw;
            filter: drop-shadow(0 0.1vw 0.1vw rgba(0, 0, 0, 0.2));
            -webkit-filter: drop-shadow(0 0.1vw 0.1vw rgba(0, 0, 0, 0.6));
          }

          & > div {
            padding: 0.2vw 0 0.2vw 1.3vw;
            flex: 1;
            position: relative;

          }

          & .buff2 {
            margin: 0.4vw 0.2vw 0 0.6vw;
          }

          & .buff3 {
            margin: 0.4vw 0.6vw 0 0.2vw;

          }
        }
      }
    }

    & .food-materials-combination {
      background-image: url("./assets/2.webp");
      background-size: 105%;
      background-repeat: no-repeat;
      background-position: center 10%;
      position: relative;
      margin-top: 0.6vw;
      margin-bottom: 1vw;
      padding-top: 0.1vw;

      & .tutorial {
        position: absolute;
        top: -3.7vw;
        left: 11.3vw;
        width: 16.4vw;
        padding-left: 0.3vw;
        padding-right: 11.2vw;
        height: 5vw;
        background-image: url("./assets/cookingBG2.webp");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;

        & .topic {
          color: #848a98;
          font-size: 0.7vw;
          font-family: 'Noto Serif SC', serif;
          margin: 1.2vw 0 0.1vw 0;
        }

        & .content {
          color: #cea251;
          font-size: 0.47vw;
        }
      }

      & .filters {
        display: flex;
        font-size: 0.7vw;
        color: #8d8d8d;
        margin: 1vw 0 0.3vw 0;

        & .filter {
          //width: 4vw;
        }

        & > div:nth-child(1) {
          margin: 0 2vw 0 3.2vw;
        }

        //& > div:nth-child(2){
        //  margin: 0 1vw 0 1vw;
        //}
        & > div:nth-child(3) {
          margin: 0 1vw 0 1vw;
        }

        & > div:nth-child(4) {
          margin: 0 1vw 0 1vw;
        }


        & > div:nth-child(5) {
          margin: 0 1vw 0 1vw;
        }

        & > div:nth-child(6) {
          margin: 0 1vw 0 1vw;
        }

        & > div:nth-child(7) {
          margin: 0 1vw 0 1vw;
        }

        & > div:nth-child(8) {
          margin: 0 1vw 0 1vw;
        }
      }

      & .matrix-root {
        display: flex;
        position: relative;
        justify-content: flex-end;
        margin: 0 1vw 0.3vw 1.5vw;
        border: 0.2vw solid rgba(0, 0, 0, 0.4);
        border-right: 0;
        border-bottom: 0.1vw solid rgba(0, 0, 0, 0.4);
        border-top-left-radius: 0.4vw;
        border-bottom-left-radius: 0.4vw;

        & .underlines-container {
          width: 95%;

          & .underline {
            margin-left: 1vw;
            height: 3.4vw;
            border-bottom: 0.1vw solid lightgray;
          }

          & div:last-child {
            border-bottom: 0;
          }
        }

        & .hint-container {
          width: 0.6vw;
          margin: 0 0.6vw;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 0.5vw;
          text-align: center;
          color: #8d8d8d;

          & img {
            height: 9.5vw;
            margin: 0.6vw 0;
          }
        }

        //食材卡片 210
        & .material-card {
          position: absolute;
          z-index: 210;
          left: -1vw;
          height: 3.1vw;
          width: 37.5vw;
          background-color: white;
          border-radius: 0.2vw;
          box-shadow: 0 -0.15vw 0.25vw rgba(0, 0, 0, 0.3);
          display: flex;
          align-items: center;
          transition: bottom ease-in-out 700ms;

          & .clear {
            color: #bd1f1f;
            margin: 0 0.7vw 0 0.4vw;
          }

          & > .two-story-text {
            margin: auto 0;

            & .up {
              font-size: 1.25vw;
              line-height: 1vw;
              margin: 0.4vw 0 0.2vw 0;

              &.spice {
                white-space: nowrap;
                padding-left: 0.1vw;

                & .hint {
                  font-size: 0.8vw;
                  color: #5f6e7b;
                }
              }
            }

            & .down {
              font-size: 0.7vw;
              line-height: 0.7vw;
              min-height: 1vw;
            }

            & .MuiInputBase-input {
              padding: 0 0 0 0.2vw;
              font-size: 1vw;
            }

            &:nth-of-type(2), {
              width: 3.8vw;
              //margin-right: -0.8vw;
              & .down {
                white-space: nowrap;
                overflow: visible;
              }

            }

            &:nth-of-type(8), &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6), &:nth-of-type(7), {
              text-align: right;
              width: 3.5vw;
              margin-right: 1vw;

              & .up {
                transform-origin: right;
                transform: scale(0.7, 1); /* W3C */
              }
            }

            &:nth-of-type(7), {
              margin-left: 2vw;
            }

            &:nth-of-type(7), &:nth-of-type(8), {
              width: 2.5vw;

              & .up {
                transform: scale(1, 1); /* W3C */
              }
            }

          }


        }

        // 烹饪方法加成数字 220
        & .method-bonus-number {
          position: absolute;
          z-index: 220;
          color: #daac56;
          font-weight: 700;
          font-size: 1.25vw;
          transform: scale(0.55, 1); /* W3C */
        }

        //添加食材卡片 230
        & .adding-material-root {
          position: absolute;
          z-index: 230;
          display: flex;
          left: 2vw;

          & .button8 {
          }
        }

        //建筑食谱食材数限制 240
        & .materials-count-limit-root {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          z-index: 240;
          left: -1vw;
          height: 3.1vw;
          width: 37.5vw;
          font-size: 0.8vw;
          color: white;
          background-color: rgba(128, 128, 128, 0.5);
          border-radius: 0.2vw;
          pointer-events: none;

        }

        //调料绘制 250
        & .spices-draw-root {
          position: absolute;
          z-index: 250;
          width: 100%;
          height: 100%;
          pointer-events: none;

          & .spice-draw-root {
            position: absolute;
            outline: solid 0.3vw;
            border-radius: 0.3vw;
            filter: drop-shadow(0 0.2vw 0.1vw rgba(0, 0, 0, 0.5));
            -webkit-filter: drop-shadow(0 0.2vw 0.1vw rgba(0, 0, 0, 0.5));

            & .title {
              position: absolute;
              top: -0.2vw;
              left: 0;
              padding: 0 0.2vw 0.1vw 0.2vw;
              font-size: 0.7vw;
              line-height: 0.7vw;
              color: white;
              border-top-left-radius: 0.3vw;
            }

          }
        }
      }

      & .results {
        display: flex;
        justify-content: flex-end;
        border-top: 0.15vw solid rgba(0, 0, 0, 0.4);
        margin: 0 1vw 0 4vw;
        padding-top: 0.4vw;

        & .result {
          width: 4.5vw;

          & .up {
            font-size: 1.25vw;
            line-height: 1.3vw;
            margin: 0.4vw 0 0 0;
            font-weight: 700;
          }

          & .down {
            font-size: 0.7vw;
            min-height: 1vw;
          }

          &.quantity-result {

          }

          &:nth-of-type(1) {
            margin-right: 1.4vw;
          }

          &:nth-of-type(6) {
            width: 3.8vw;
            margin-left: 0.2vw;
          }

          &:nth-of-type(7) {
            margin-right: 0.2vw;
          }
        }
      }

    }

    //燃料消耗和烹饪消耗
    & .consume-container {
      display: flex;
      margin: 0.5vw 0 0.5vw 3.8vw;

      & .consume-right-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #5f6e7b;
        margin-left: 0.4vw;

        & .top {
          & .thick-black {
            font-weight: 700;
            color: black;

          }
        }

        & .down {
          font-size: 0.8vw;
        }
      }
    }

    //食材非法警告信息
    & .warnings {
      margin-top: 2vw;

      & .warning {
        text-align: center;
        margin: 0 auto 0.4vw auto;
        color: #e74652;
        font-size: 0.9vw;
        font-weight: 900;

      }
    }

    & .apply-buttons {
      margin: 1vw 0 5vw 0;
      display: flex;
      justify-content: center;

      & .MuiButton-root {
        margin: 0.25vw 2.5vw;
        padding: 0;
        max-width: 7vw;
        min-width: 6vw;
        max-height: 1.8vw;
        min-height: 2vw;
        font-size: 0.8vw;
        font-weight: 700;

      }
    }


  }

  //  灰色
  & .grey {
    color: rgb(89, 107, 119);
  }
}