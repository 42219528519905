.progress-dots-root {
  display: flex;
  justify-content: center;
  align-items: center;

  & .progress-dots-dots-container {
    display: flex;

    & .progress-dot {
      width: 0.5vw;
      height: 0.5vw;
      border-radius: 50%;
      background-color: lightgray;
      margin: 0 0.2vw;

      &.current {
        background-color: #5f6e7b;
      }
    }
  }

  & .progress-dots-dash {
    width: 3vw;
    border-bottom: solid 0.1vw lightgray;
    margin: 0 0.3vw;
  }
}