.district-and-bazaar-tab{
  & .tab-button{
    width: 12vw;
    height: 5.5vw;
    margin-bottom: 0.3vw;
    -webkit-filter: drop-shadow(0 0.3vw 0.2vw rgba(0, 0, 0, 0.3));
    background-size: 100% auto;
    background-repeat: no-repeat;
    //transition: all 100ms;
    transition: transform 100ms ;
    &:hover{
      transform: translateX(3%);
    }
    &.active{
      transform: translateX(16%);
    }
  }
  & .overview{
    height: 5.0vw;
    background-image: url("assets/DBTabs-overview-east.webp");
  }
  & .overview-active{
    background-image: url("assets/DBTabs-overview-east-active.webp");
  }
  & .recruit{
    height: 4.9vw;
    background-image: url("assets/DBTabs-recruit-east.webp");
  }
  & .recruit-active{
    background-image: url("assets/DBTabs-recruit-east-active.webp");
  }
  & .apply{
    height: 4.6vw;
    background-image: url("assets/DBTabs-apply-east.webp");
  }
  & .apply-active{
    background-image: url("assets/DBTabs-apply-east-active.webp");
  }
  & .restaurant{
    height: 5.2vw;
    background-image: url("assets/DBTabs-restaurant-east.webp");
  }
  & .restaurant-active{
    background-image: url("assets/DBTabs-restaurant-east-active.webp");
  }
  & .housing{
    height: 5.7vw;
    background-image: url("assets/DBTabs-housing-east.webp");
  }
  & .housing-active{
    background-image: url("assets/DBTabs-housing-east-active.webp");
  }
  & .goods{
    height: 5.7vw;
    background-image: url("assets/DBTabs-goods-east.webp");
  }
  & .goods-active{
    background-image: url("assets/DBTabs-goods-east-active.webp");
  }
  & .buildings{
    height: 5.7vw;
    background-image: url("assets/DBTabs-buildings-east.webp");
  }
  & .buildings-active{
    background-image: url("assets/DBTabs-buildings-east-active.webp");
  }

}