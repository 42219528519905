.workingStrategy-root .card-element {
  border-radius: 0.5vw;
  box-shadow: 0 0 0.5vw rgba(0, 0, 0, 0.4);
}

.workingStrategy-root .sub-title {
  font-size: 1.2vw;
  color: black;
  opacity: 0.5;
  font-family: 'Noto Serif SC', serif;
  font-weight: 900;
  margin: 0 0.5vw;
  padding: 0.1vw 0;
  //border-bottom: 1px solid grey;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;

  & .sub-title-icon {
    position: relative;
    top: 0.2vw;
    margin-left: 0.3vw;
    margin-right: 0.1vw;
  }
}

.workingStrategy-root .skill-growth-bullet {
  width: 5vw;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  margin: 0.25vw 1.5vw 0.25vw 1.5vw;

  & .skill-icon {
    width: 2.2vw;
    height: 2.2vw;
  }

  & .bullet-right {
    min-width: 3vw;
    margin-left: 0.5vw;
    color: #34af31;

    & .skill-value {
      font-size: 1.25vw;
      font-weight: 500;
      line-height: 1.2vw;
    }

    & .skill-name {
      font-size: 0.7vw;
    }
  }
}


.workingStrategy-root .outcome-row {
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  width: 100%;
  color: grey;
  font-size: 0.9vw;

  & .outcome-name {
    width: 60%;
  }

  & .outcome-value {
    width: 40%;
    text-align: right;
  }
}

.workingStrategy-root {
  position: absolute;
  width: 41vw;
  height: 43vw;
  //background-color: grey;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & .workingStrategy-white-background {
    position: absolute;
    background-color: white;
    width: 100%;
    height: 96%;
    border-radius: 1vw;
  }

  & .workingStrategy-content {
    position: relative;
    z-index: 2;

    & .title {
      height: 2.1vw;
      padding: 1vw 1vw;

      & .title-icon {
        display: inline-block;
        cursor: pointer;
      }

      & .title-text {
        font-size: 1.5vw;
        color: black;
        opacity: 1;
        font-family: 'Noto Serif SC', serif;
        font-weight: 900;
        display: inline-block;
        transform: translateY(-45%);
        margin-left: 1.5vw;
      }
    }

    & .pre-work {
      height: 16.4vw;
      width: 100%;
      padding: 1vw 4.7vw;
      display: flex;
      -webkit-flex-direction: row;
      flex-direction: row;

      & .left-column {
        width: 16.4vw;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;

        & .status {
          width: 15.4vw;
          height: 10vw;
          margin: 0.4vw 0.8vw;

          & .status-content {
            display: flex;
            -webkit-flex-direction: row;
            flex-direction: row;

            & .status-bars {
              margin: 0.4vw 0 0 0.5vw;
            }
          }
        }

        & .skill {
          width: 15.4vw;
          max-height: 10vw;
          height: 8vw;
          margin: 0.4vw 0.8vw;

          & .skill-content {
            display: flex;
            height: 4.4vw;
            justify-content: center;
            align-items: center;

            & .skill-bullet {
              display: flex;
              align-items: center;
              flex-direction: row;
              margin-right: 0.3vw;

              & .skill-icon {
                width: 2.2vw;
                height: 2.2vw;
              }

              & .bullet-right {
                min-width: 3vw;
                margin-left: 0.5vw;

                & .skill-value {
                  font-size: 1.25vw;
                  font-weight: 400;
                  line-height: 1.2vw;
                }

                & .skill-name {
                  font-size: 0.7vw;
                  color: #596a76;
                }
              }
            }
          }
        }
      }

      & .personality {
        margin: 0.4vw 0.8vw;
        width: 13.6vw;
        height: 15.6vw;

        & .personality-content {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 12vw;

          & div {
            font-size: 0.8vw;
            color: #5f6e7b;
            text-align: center;
          }
        }
      }
    }

    & .after-work {
      height: 16.4vw;
      width: 100%;
      padding: 0vw 4.7vw 2vw 4.7vw;
      display: flex;
      -webkit-flex-direction: row;
      flex-direction: row;

      & .outcome {
        margin: 0.4vw 0.8vw;
        width: 10vw;
        height: 15.6vw;

        & .outcome-content {
          margin: 0.5vw 10%;

          & .final-capacity-row {
            display: flex;
            -webkit-flex-direction: row;
            flex-direction: row;
            //color: grey;
            font-size: 0.9vw;

            & .final-capacity-name {
              width: 60%;
            }

            & .final-capacity-value {
              width: 40%;
              text-align: right;
            }
          }

          & .outcome-belongs-to-landlord {
            color: grey;
            font-size: 0.6vw;
            margin: 0.5vw 0;
            border-top: 1px solid grey;
          }
        }
      }

      & .right-column {
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;

        & .skill-growth {
          width: 18.4vw;
          height: 4.8vw;
          margin: 0.4vw 0.8vw;

          & .skill-growth-content {
            display: flex;
            -webkit-flex-direction: row;
            flex-direction: row;
          }
        }

        & .wage {
          position: relative;
          width: 18.4vw;
          height: 9vw;
          margin: 0.4vw 0.8vw;
          cursor: pointer;

          & .wage-img {
            position: absolute;
            left: -3vw;
            top: -1.5vw;
            width: 26.5vw;
          }

          & .content {
            margin: 2.5vw 5vw;

            & .wage-sub-title {
              font-size: 1.2vw;
              color: black;
              opacity: 0.5;
              font-family: 'Noto Serif SC', serif;
              font-weight: 900;
              margin: 0 0.5vw;
              padding: 0.1vw 0;
              display: flex;
              -webkit-flex-direction: row;
              flex-direction: row;

              & .sub-title-icon {
                position: relative;
                top: 0.2vw;
                margin-left: 0.3vw;
                margin-right: 0.1vw;
              }
            }

            & .wage-icon-number {
              width: 7vw;
              margin-left: 1vw;
              position: relative;
              z-index: 3;
              display: flex;
              -webkit-flex-direction: row;
              flex-direction: row;
              -webkit-filter: drop-shadow(0 0 0.15vw #4c2600);
              filter: drop-shadow(0 0 0.15vw #4c2600);

              & .wage-number {
                font-size: 2.2vw;
                color: white;
                font-weight: 500;
                width: 5vw;
                position: relative;
                top: -0.5vw;
                margin-left: 0.5vw;
                -webkit-text-stroke: 1px #4c2600;
                //text-align: right;
              }
            }
          }
        }
      }
    }

    & .strategy {
      position: absolute;
      height: 17.4vw;
      width: 30vw;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      border-radius: 0.5vw;
      //box-shadow: 0 0 1.5vw rgba(0, 0, 0, 0.7);
      //box-shadow: 0 0 0.4vw rgba(0, 0, 0, 0.4);
      background-color: white;
      opacity: 1;
      //top: 22.5vw;
      //top: 4vw;
      left: 5.5vw;
      z-index: 10;
      overflow: hidden;
      transition-duration: 200ms;

      & .swipeable-container {
        margin: 0 auto;
        position: relative;
        top: 0.4vw;

        & .choice-card {
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;

          & .top-card {
            position: relative;
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 83%;
            transition-duration: 200ms;
            cursor: pointer;

            & .choice-title {
              font-size: 1.5vw;
              padding: 0.7vw 0;
              text-align: center;
              pointer-events: none;

            }

            & .choice-description {
              text-align: center;
              pointer-events: none;

            }
          }

          & .bottom-bar {
            position: absolute;
            width: 100%;
            height: 2.4vw;
            background-image: url("./assets/card-base.webp");
            background-size: contain;
            background-repeat: no-repeat;
            top: 12.9vw;

          }
        }
      }
    }
  }
}

.top-card.not-available {
  background-image: url("./assets/strategy1inactive.webp");
  top: 1.55vw;
  color: #545454;

  & .choice-title {

  }

  & .choice-description {
    font-size: 1vw;

  }
}

.top-card.available {
  background-image: url("./assets/strategy1standard.webp");
  top: 1.55vw;
  color: white;

  & .choice-title {
    -webkit-filter: drop-shadow(0 0 0.2vw black);
    filter: drop-shadow(0 0 0.2vw black);

  }

  & .choice-description {
    font-size: 0.7vw;
    -webkit-filter: drop-shadow(0 0 0.2vw black);
    filter: drop-shadow(0 0 0.2vw black);

  }
}

.top-card.picked {
  background-image: url("./assets/strategy1picked.webp");
  top: 0;
  color: white;

  & .choice-title {
    -webkit-filter: drop-shadow(0 0 0.2vw black);
    filter: drop-shadow(0 0 0.2vw black);

  }

  & .choice-description {
    font-size: 0.7vw;
    -webkit-filter: drop-shadow(0 0 0.2vw black);
    filter: drop-shadow(0 0 0.2vw black);

  }
}