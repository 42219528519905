.vertical-progress-root {
  width: 0.7vw;
  height: 100%;
  border-radius: 0.35vw;
  box-shadow: 0 0 1vw rgba(63, 108, 0, 1);
  overflow: hidden;

  & .vertical-progress {
    position: relative;
    background-color: #93ce3f;
    width: 100%;
    height: 100%;
  }
}

.promo-root {
  position: absolute;
  width: 24vw;
  height: 8vw;
  //background-color: #75ba38;
  cursor: pointer;
  z-index: 890;
  -webkit-filter: drop-shadow(0 0.5vw 0.5vw rgba(234, 220, 192, 0.63));
  filter: drop-shadow(0 0.5vw 0.5vw rgba(234, 220, 192, 0.63));
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  & .bg1 {
    left: 0;
    position: absolute;
    width: 33vw;
    height: 10vw;
    background-image: url("assets/bg1.webp");
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 900;
  }

  & .bg2 {
    left: 0;
    top: 5vw;
    position: absolute;
    width: 33vw;
    height: 42vw;
    background-image: url("assets/bg2.webp");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 894;

  }

  & .content {
    cursor: auto;
    position: relative;
    top: 5vw;
    width: 26vw;
    height: 28vw;
    z-index: 895;
    padding: 3vw 3.5vw 5vw 3.5vw;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    & .intro {
      display: flex;
      width: 100%;
      height: 7.5vw;

      & .intro-text {
        flex: 2.3;
        color: white;
        margin: 1.5vw 0.5vw 0 0.5vw;
        text-align: left;
        font-size: 0.85vw;
        line-height: 1.5vw;

      }

      & .logo {
        flex: 1;
        background-image: url("assets/logo.webp");
        background-size: contain;
        background-repeat: no-repeat;

      }
    }

    & .contacts {
      display: flex;
      justify-content: space-around;
      margin-bottom: 0.5vw;

      & .MuiButton-root {
        min-width: 7.5vw;
        display: flex;
        align-items: center;
        padding: 0;
        & .MuiSvgIcon-root{
         font-size: 1vw;
          margin-right: 0.2vw;
        }
      }
    }

    & .steam {
      position: relative;
      height: 8vw;

      & iframe {
        //transition: transform 200ms;
        //max-width: 100%;
        transform-origin: top left
      }
    }

    & .story {
      color: grey;
      text-align: left;
      font-size: 0.85vw;
      line-height: 1.2vw;
      margin-bottom: 0.5vw;
    }

    & .filters {
      display: flex;
      justify-content: space-between;

      & .filter {
        & div {
          width: 3.2vw;
          color: white;
          font-size: 0.65vw;
          font-weight: 300;
          line-height: 1.5vw;
          margin-right: -1vw;
        }
      }

      & .r {
        display: flex;
        margin-right: 1vw;

        & div {
          transform: scaleX(0.7);

        }
      }
    }

    & .update-block {
      position: relative;
      background-color: black;
      border-radius: 0.7vw;
      text-align: left;
      padding: 0.5vw 0.5vw 0.5vw 1vw;
      margin-bottom: 0.5vw;

      & .progress-container {
        position: absolute;
        padding-top: 0.5vw;
        height: calc(100% - 2vw);
        right: 3.5vw;
      }

      & .title {
        color: #ff8842;
        font-family: 'Noto Serif SC', serif;
        font-size: 1.3vw;
        font-weight: 900;
        line-height: 2.2vw;
      }

      & .line {
        color: white;
        display: flex;
        justify-content: space-between;

        & .l {
          flex: 1.8;
        }

        & .r {
          flex: 1;
          display: flex;
          text-align: center;

          & div {
            flex: 1;
          }
        }
      }

      & .second-title {

      }

      & .description {
        color: #808080;
        font-size: 0.7vw;
        margin-bottom: 0.5vw;
      }

      & .summary {
        font-size: 0.8vw;
        text-align: center;
        color: #808080;
        opacity: 0.5;
        margin-top: 0.5vw;

        &.orange {
          color: #ff8842;

        }
      }
    }

    & .orange {
      color: #ff8842;
      margin-bottom: 0.5vw;

    }
  }
}