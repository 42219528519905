.personalRoot::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

//去掉Link组件文字下划线
.personalRoot a {
  text-decoration: none;
}

//列表无内容时候的描述文字。小字居中上下留空
.personalRoot .absence-description {
  color: #5f6e7b;
  font-size: 0.8vw;
  margin: 1.5vw 0;
}

//通用文字列表条目
.personalRoot .text-list-bullet {
  //margin: 1vw 0 1vw 1vw;
}

//藏书条目
.personalRoot .book-list-bullet {
  color: #5f6e7b;
  font-size: 0.8vw;
  display: flex;
  justify-content: space-between;

  & .left {
    display: flex;
    align-items: center;

    & .title {
      color: black;
      font-size: 1vw;
      font-weight: 700;
    }
  }
}

//可展开标题行的指针样式
.personalRoot .expandable-title-line {
  cursor: pointer;
}

//通用橘色条目标题
.personalRoot .title-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #b38460;

  & .title-line-text {
    display: flex;
    align-items: center;
    color: #b38460;
    margin: 0;

    font-size: 1.5vw;
    font-family: 'Noto Serif SC', serif;
    font-weight: 900;
    text-align: left;
  }
}

.personalRoot {
  height: calc(100vh - 5vw);
  position: relative;
  padding-left: 2vw;
  display: flex;
  overflow-y: scroll;


  & .left-container {
    flex: 1.6;
    border-top-left-radius: 1vw;
    //padding-bottom: 10vw;
    //margin-bottom: 10vw;

    //菜单栏
    & .menu-bar {
      height: 2.8vw;
      width: 100%;

      & .buttons {
        float: right;
        margin-right: 0.5vw;
        display: flex;

        & .button {
          min-height: 2.2vw;
          min-width: 2.2vw;
          margin: 0.3vw 0;
          padding: 0.6vw 0.8vw;
          font-size: 0.8vw;
          color: #5f6e7b;
          border-color: #5f6e7b;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    //banner栏
    & .banner-container {
      width: 100%;
      height: 9.8vw;
      margin-bottom: -2vw;
      position: relative;

      & .banner {
        position: absolute;
        top: 0;
        width: 104%;
        left: -2%;
        height: 100%;
        background-image: url("assets/personal-banner.webp");
        background-size: contain;
        background-repeat: no-repeat;
        -webkit-filter: drop-shadow(0 0.3vw 0.6vw rgba(0, 0, 0, 0.4));
        filter: drop-shadow(0 0.3vw 0.6vw rgba(0, 0, 0, 0.4));
        display: flex;
        flex-direction: row;
        z-index: 4;

        & .ribbons {
          flex: 2;
          display: flex;
          flex-direction: column;
          justify-content: center;

          & .ribbon {
            background-image: url("assets/personal-ribbon.webp");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right;
            color: white;
            font-size: 0.8vw;
            font-family: 'Noto Serif SC', serif;
            font-weight: 500;
            text-align: right;
            padding-right: 3vw;
            height: 1.4vw;
            width: 11vw;
            -webkit-filter: drop-shadow(0 0.3vw 0.2vw rgba(0, 0, 0, 0.5));
            filter: drop-shadow(0 0.3vw 0.2vw rgba(0, 0, 0, 0.5));
            position: relative;
          }

          & .ribbon1 {
            left: 3vw;
          }

          & .ribbon2 {
            margin: 0.3vw 0 1vw 0;
            left: -1vw;
          }

          & .ribbon3 {
            left: 1.5vw;
          }
        }

        & .pfp {
          flex: 1.1;
          -webkit-filter: drop-shadow(0 0.3vw 0.2vw rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0 0.3vw 0.2vw rgba(0, 0, 0, 0.5));
          display: flex;
          align-items: center;
          justify-content: center;

        }

        & .name {
          flex: 2;
          display: flex;
          flex-direction: column;
          justify-content: center;

          & .name-text {
            font-size: 2.15vw;
            line-height: 2.1vw;
            font-family: 'Noto Serif SC', serif;
            font-weight: 900;
            text-align: left
          }

          & .description {
            color: #5f6e7b;
            text-align: left;
            font-size: 0.8vw;
            font-weight: 700;
          }
        }
      }

      //banner角标
      & .tagIcon {
        position: absolute;
        top: 1.2vw;
        left: 36vw;
        z-index: 5;
        width: 3.6vw;
        height: 0.8vw;
        background-image: url("./assets/gold-leaf-texture-small.webp");
        color: #4d2200;
        font-size: 0.6vw;
        line-height: 0.8vw;
        font-family: 'Noto Serif SC', serif;
        font-weight: 300;
        white-space: nowrap;
        border-radius: 0.2vw;
        border: 0.15vw solid white;
        letter-spacing: 0.1vw;
      }

    }

    & .relation-skill {
      display: flex;
      flex-direction: row;
      height: 12.2vw;
      margin: auto 0.7vw;

      //人际关系的独特样式
      & .relation {
        background-image: url("assets/personal-blank-left.webp");
      }

      //技能水平的独特样式
      & .skill {
        background-image: url("assets/personal-blank-left.webp");

        & img {
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }
      }

      //人际关系和技能水平的通用样式
      & .RS-card {
        display: flex;
        flex-direction: column;
        width: 50%;
        max-width: 50%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
        position: relative;

        //”人际关系“”技能水平“大标题
        & .title {
          color: #e0cdbe;
          font-size: 3vw;
          font-family: 'Noto Serif SC', serif;
          font-weight: 900;
        }

        & .content {
          display: flex;
          flex-direction: row;
          //总和
          & .sum {
            flex: 1.4;
            text-align: right;
            //总和数字
            & .sum-number {
              position: relative;
              margin-top: -0.5vw;
              margin-right: 1vw;
              font-size: 3.5vw;
              font-weight: 500;
              line-height: 3.5vw;
            }

            //”关系总和“”技能总和“
            & .sum-title {
              margin-top: 0vw;
              color: #5f6e7b;
              font-size: 1.4vw;
              font-weight: 500;
              letter-spacing: 0.05em;
              margin-right: 1.1vw;
            }
          }

          //举例2则
          & .examples {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            //单个举例
            & .example {
              display: flex;
              flex-direction: row;

              & img {
                width: 2.15vw;
                height: 2.15vw;
              }

              //单个举例右侧文字
              & .example-text {
                margin-left: 0.4vw;
                text-align: left;

                & .example-text-up {
                  font-weight: 700;
                  font-size: 1.4vw;
                  line-height: 1.1vw;
                }

                & .example-text-down {
                  color: #5f6e7b;
                  font-size: 0.8vw;
                  white-space: nowrap;
                }
              }
            }
          }
        }

        //数字很大时候的炫酷星星特效
        & .fancyLargeNumber {
          background-image: url("./assets/gold-leaf-texture-small.webp");
          background-size: cover;
          //background-clip: text;
          -webkit-background-clip: text;
          color: transparent;

          //星星动画
          //$randomNumber: random(100);

          @keyframes rotating-star {
            100% {
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
          @keyframes blinking-star {
            0% {
              opacity: 0;
            }
            3% {
              opacity: 1;
            }
            8% {
              opacity: 1;
            }
            30% {
              opacity: 0;
            }
            100% {
              opacity: 0;
            }

          }


          &::before, &::after {
            position: absolute;
            content: "✦";
            font-size: 1.4vw;
            line-height: 1vw;
            color: #fff0e3;
            filter: drop-shadow(0 0 0.3vw #be6500);
            -webkit-filter: drop-shadow(0 0 0.3vw #be6500);
            animation: rotating-star 2.5s infinite linear, blinking-star 4s infinite;
          }

          &::before {
            top: 0;
            left: 25%;

          }

          &::after {
            bottom: 20%;
            right: 10%;
            animation-delay: 0.6s;

          }
        }
      }
    }

    & .books {
      margin: 0.3vw 2.5vw;

    }

    & .work-n-residence {
      display: flex;

      & .work {
        flex: 1;
        margin: 0.7vw 0 0.3vw 2.5vw;

        & .work-card-container {
          height: 5.5vw;
          width: 15.3vw;
          cursor: pointer;

          background-size: 150%;
          background-position: 0 60%;
          margin: 0.5vw 0.5vw 1vw 1.5vw;
          border-radius: 0.3vw;
          box-shadow: 0 0.15vw 0.4vw 0 rgba(0, 0, 0, 0.4);
          color: #5f6e7b;
          font-size: 0.8vw;
          display: flex;
          align-items: center;

          & .title {
            color: black;
            font-size: 1vw;
            font-weight: 700;
            margin-right: 0.3vw;
            margin-left: 1vw;
          }

        }
      }

      & .residence {
        flex: 1;
        margin: 0.7vw 0 0.3vw 2.5vw;

        & .residence-card-container {
          height: 5.5vw;
          width: 15.3vw;
          cursor: pointer;
          background-size: 150%;
          background-position: 0 60%;
          margin: 0.5vw 0.5vw 1vw 1.5vw;
          border-radius: 0.3vw;
          box-shadow: 0 0.15vw 0.4vw 0 rgba(0, 0, 0, 0.4);
          color: #5f6e7b;
          font-size: 0.8vw;
          display: flex;
          align-items: center;

          & .title {
            color: black;
            font-size: 1vw;
            font-weight: 700;
            margin-right: 0.3vw;
            margin-left: 1vw;
          }

        }
      }
    }

    & .my-buildings {
      margin: 0.3vw 2.5vw;

      & .MuiListItemButton-root {
        padding: 0.4vw 1vw;
      }
    }
  }

  & .right-container {
    flex: 1.2;
    position: relative;
    //padding-bottom: 10vw;

    & .social {
      margin-top: 2.4vw;
      margin-bottom: 0.9vw;

    }

    & .social-container {
      background-color: #f2f2f2;
      margin: 0.4vw 1.8vw;
      width: auto;
      border-radius: 0.4vw;
      box-shadow: inset 0 0 0.5vw rgba(0, 0, 0, 0.1);
      padding: 0 0.3vw;
      justify-content: space-between;
    }

    & .recent-visits {
      height: 4vw;
      display: flex;
      flex-direction: row;

      & .heads-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 0.5vw;
      }

      & .visitor-counter {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #5f6e7b;
        font-size: 0.9vw;
        margin-right: 0.9vw;

        & .bold {
          color: black;
          font-weight: 500;
          font-size: 1.4vw;
        }
      }
    }

    & .recent-interactions {
      height: 6vw;
      margin-bottom: 2vw;
      padding-top: 0.4vw;

      & .interactions-top-container {

        & .interactions-bullet {
          display: inline-block;
          margin: 0 0.3vw;

          & .up {
            display: flex;
            flex-direction: row;
            align-items: center;

          }

          & .down {
            width: 8vw;
            color: #5f6e7b;
            font-size: 0.8vw;

          }
        }
      }

      & .interaction-scroll-bar {

      }
    }
  }
}