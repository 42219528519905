.working-method {
  margin-left: 3vw;
  & .method-container-container {
    display: flex;
    margin: 0.7vw 0.5vw 0.7vw 0;

    & .method-container {
      cursor: pointer;
      width: 18.5vw;
      border-radius: 0.4vw;
      border: solid 0.2vw white;
      transition: border-color 200ms;

      &.chosen {
        border-color: #fd7700;
      }
    }

    & .m-text {
      margin: 0.2vw 0 0 1vw;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      font-size: 0.7vw;

      & .text1 {
        color: #5a6a77;
        text-align: left;
      }

      & .tag {
        color: white;
        background-color: #ed6c02;
        padding: 0 0.2vw;
        width: 3vw;
        margin-bottom: 0.2vw;
        white-space: nowrap;

        &.red {
          background-color: #e74652;

        }
      }
    }
  }
}