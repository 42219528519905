.TransitModal {
  width: 50vw;
  min-height: 30vw;
  background-color: white;
  border-radius: 0.5vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1vw;

  //两个mui select 的fix
  & .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
    width: 42vw;
  }
  & .MuiSelect-select {
    padding: 0.8vw 3.2vw 0.8vw 1.4vw;
  }
  & .title-line {
    display: flex;
    & .close{
      font-size: 4vw;
      cursor: pointer;
    }
    & .r {
      & .title {
        font-size: 1.5vw;
        color: black;
        font-family: 'Noto Serif SC', serif;
        font-weight: 900;
      }

    }
  }
  & .building-line{
    width: 42vw;
    margin: 0 auto;
  }
  & .destination-building{
    width: 50vw;
    margin: 0 auto;
  }
  & .buttons{
    margin: 2vw 10vw;
    display: flex;
    justify-content: space-around;
  }
  & .quantity{
    margin: 0 0.5vw 0 0;
    padding: 0;

    & .MuiInputBase-input {
      padding: 0;
      height: 1.8vw;
      overflow: visible;
    }

  }
}
