//选建筑种类的
.district-overview .building-selection-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  //transform: scale(1.1);
  //top: 1.1vw;
  width: 54vw;
  height: 30.8vw;
  margin: 2.5vw auto 0 auto;
  padding: 0.1vw 1vw 1vw 1vw;
  background-image: url("./assets/BPBuildingSeletionFrame.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  & .building-selection-title {
    position: relative;
    top: 0.2vw;
    width: 47vw;
    font-size: 0.7vw;
    line-height: 0.7vw;
    padding-left: 1vw;
    font-family: 'Noto Serif SC', serif;
    font-weight: 700;
    color: #064562;
    text-align: left;
  }

  //建筑选择，厚方框的整个
  & .building-selection::-webkit-scrollbar {
    //display: none; /* Chrome Safari */
  }

  & .building-selection {
    border: 0.3vw solid #064562;
    margin-top: 0.3vw;
    width: 47vw;
    height: 28vw;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    //建筑选择，单条
    & .selection-bullet {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 14vw;
      border-bottom: 0.3vw solid #064562;
      position: relative;
      //左边，整个切换星级的部分
      & .l {
        flex: 0.9;
        display: flex;
        flex-direction: column;
        //单个名字星级的容器，多包一层方便变形
        & .title-container {
          height: 2vw;
          max-height: 2vw;
          width: 100%;
          border-bottom: 0.1vw solid #064562;
          border-right: 0.1vw solid #064562;
          overflow: hidden;
          background-image: url("./assets/BPDashedBG.webp");
          background-size: cover;
          cursor: pointer;
          transition: all 200ms;

          //title-container的
          &.selected {
            height: 7.9vw;
            max-height: 7.9vw;
            border-right: none;
            background-image: none;
          }
          & .title {
            min-height: 6.25vw;
            width: 100%;
            position: relative;
            top: 0;
            transition: all 200ms;

            & .up {
              display: flex;
              justify-content: left;

              & .name {
                width: 6.5vw;
                text-align: right;
                color: #064562;
                font-size: 1.1vw;
              }

              & .star {
                color: #064562;
                font-size: 1.4vw;
                line-height: 1.5vw;
                letter-spacing: -0.1em;
                margin-left: 0.2vw;
              }


              & .no-support {
                position: absolute;
                z-index: 5;
                width: 3vw;
                height: 3vw;
                font-size: 3vw;
                top: -1vw;
                left: 1vw;
                transition: top 200ms;
                background-size: contain;
                color: #064562;
                margin: 0;
                opacity: 0;

                //no-support的
                &.selected {
                  top: 0.2vw;
                }

                &.x {
                  opacity: 1;
                }
              }

              & .chosen {
                position: absolute;
                z-index: 5;
                width: 8vw;
                height: 8vw;
                top: -3.3vw;
                left: -2.8vw;
                transition: top 200ms, opacity 100ms;
                background-size: contain;
                opacity: 0;

                //chosen的
                &.selected {
                  top: -1.5vw;
                }

                &.x {
                  opacity: 1;
                }
              }


            }

            & .BPArrow {
              width: 90%;
              height: 0.42vw;
              background-image: url("./assets/BPArrow.webp");
              background-repeat: no-repeat;
              background-size: contain;
              position: relative;
              margin-left: 1vw;
            }

            & .down.introduction_container {
              width: 12vw;
              padding-left: 1vw;
              color: #064562;
              font-size: 0.7vw;
              text-align: end;


              & .alert {
                text-align: inherit;
                color: white;
                border-radius: 0.2vw;
                background-color: #064562;
                width: auto;
              }

              & .introduction {
                text-align: inherit;
              }

              & .star-explainer {
                text-align: inherit;
              }
            }

            //title（单个星级名字的内部）的
            &.selected {
              top: 1vw;
            }
          }

        }


        //title-container的
        & .last {
          border-bottom: none;
        }
      }

      & > .m {
        flex: 1;
        //max-width: 11.5vw;
        margin: 0.5vw 0 0.5vw 1vw;
        padding: 0 0.5vw 0 0;
        border-right: 0.1vw solid #064562;

        //工位，占地面积 - 生产方案
        & .info {

        }

        & .methods {
          overflow-y: auto;
          max-height: 12.6vw;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          &::-webkit-scrollbar {
            display: none; /* Chrome Safari */
          }

          & .method-bullet-component {
            margin: .2vw 0;
            border-color: rgba(0,0,0,.05);
          }
        }
      }

      & .r {
        flex: 0.8;
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 0.5vw 0 0.5vw 0.5vw;

        & .into {

        }

        & .required-list {
          overflow-y: auto;
          max-height: 10.4vw;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          &::-webkit-scrollbar {
            display: none; /* Chrome Safari */
          }

          & .required-bullet {
            display: flex;
            margin: 0.3vw 0;

            & .amount-and-name {
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-left: 0.1vw;

              & .amount {
                font-size: 1.25vw;
                line-height: 1.3vw;
              }

              & .name {
                font-size: .7vw;
              }
            }
          }
        }


        & .to-finish {
          position: absolute;
          bottom: 0;
        }


      }
    }
  }
}

//不提供该星级的x和描述居中大字
.district-overview .x_Icon {
  color: #064562;
  font-size: 4vw;
  margin: 1.5vw auto 0 auto;
}

//蓝色居中大字,有padding此处专用
.district-overview .text_big_center {
  font-size: 0.8vw;
  //line-height: 0.7vw;
  font-weight: 700;
  text-align: center;
  color: #064562;
}
