//已导入进supervisor index
.supervisor {
  position: relative;
  max-height: calc(100vh - 17vw);
  overflow: auto;

  //去掉所有mui按钮padding。实验性
  & .MuiButtonBase-root.MuiButton-root {
    padding: 0;
  }

  //统一淡蓝字
  & .text {
    margin: 0 4vw;
    color: #596a76;
    font-size: 0.8vw;
  }

  //条件检查的通用样式
  //示例：建筑不是一座合并后的建筑。
  & .condition {
    margin-left: 1vw;
    font-size: 0.8vw;
    color: #5f6e7b;
  }

  //执行按钮那一行
  & .execute-btn-container {
    display: flex;
    margin-right: 1vw;
    justify-content: flex-end;

    & .MuiButton-root {
      max-height: 2vw;
      min-height: 2vw;
      max-width: 6vw;
      min-width: 6vw;
      font-size: 0.8vw;
    }
  }

  & .MuiDivider-root.MuiDivider-middle {
    margin-top: 2vw;
  }


  & .sub-title {
    color: #596a76;
    margin-left: 2.5vw;
    font-size: 1.4vw;
    font-family: 'Noto Serif SC', serif;
    font-weight: 900;

    & .MuiSvgIcon-root {
      position: relative;
      top: 0.25vw
    }
  }

  & .sub-text {
    margin: 0 2.5vw 0.5vw 2.5vw;
    color: #596a76;
    font-size: 0.8vw;
  }

  & .supervisor-title-line {
    width: 88%;
    position: relative;
    margin-left: 2vw;
    margin-top: 0.8vw;

    & .title {
      //margin-left: 4vw;
      font-size: 1.4vw;
      font-family: 'Noto Serif SC', serif;
      font-weight: 900;
    }

    & .title-text {
      color: #596a76;
      font-size: 0.8vw;

    }

  }

  & .supervisor-content {
    //max-height: calc(100vh - 23vw);
    //overflow: auto;
    padding-bottom: 10vw;

    & .supervisor-backdrop {
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: not-allowed;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 100;

      & .lock {
        margin: 5vw auto;
        color: white;

        & .MuiSvgIcon-root {
          font-size: 6vw;
          display: block;
          margin: 0 auto;
        }

        & div {
          margin-top: 0.6vw;
          font-size: 1.2vw;
          text-align: center;
          font-weight: 500;
        }
      }
    }


  }

}