.supervisor-check-mark-root {
  display: inline-block;
  font-size: 0.7vw;
  min-width: 0.7vw;
  width: 0.7vw;
  max-width: 0.7vw;
  text-align: center;

  &.check {
    color: #75ba38;
  }

  &.cross {
    color: #bd1f1f;
  }
  &.dot{

  }
}