.pick-building-list-root {
  width: 23vw;
  height: 30vw;
  & .title {
    text-align: center;
    font-size: 0.8vw;
    color: #5f6e7b;
    margin: 0.1vw 0;
  }

  & .list {
    & .row {
      display: flex;
      justify-content: center;
      margin: 0.2vw 0;
      padding: 0;
      align-items: center;
      cursor: pointer;



     //选择器里的建筑条目，最小宽度
     & .storage-building {
        min-width: 60%;
      }
    }
  }
}


//交易物品的,物品仓库建筑信息
.storage-building {
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  min-height: 3vw;
  margin: 0.5vw;
  padding: 0.3vw 0;

  & .MuiAvatar-root {
    margin-left: 0.5vw;
  }

  & .building-infos {
    text-align: left;
    vertical-align: middle;

    & .MuiSvgIcon-root {
      font-size: 1vw;
    }

    & .name {
      display: flex;
      align-items: center;
      font-weight: 700;
    }

    & .text {
      display: flex;
      align-items: center;
      font-size: 0.8vw;
      line-height: 0.9vw;
      //color: rgba(6,69,98,0.6);
      color: #5f6e7b;
    }
  }

  //如果是选择器
  &.selector {
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.3vw;
    border: 0.15vw solid rgba(255, 255, 255, 1);
    outline: 0.2vw solid #ed6c02;
    cursor: pointer;
    transition: background-color 300ms;

    &:hover {
      background-color: rgba(0, 0, 0, 0.65);

    }

    & .name {
      color: white;
    }

    //如果有做出选择
    &.selected {
      background-color: rgba(0, 0, 0, 0);

      & .name {
        color: black;
      }
    }
  }

}