.guest-rooms-root {
  & .guest-rooms-title {
    font-size: 1.4vw;
    font-family: 'Noto Serif SC', serif;
    font-weight: 900;
    margin: 0 0 0 4vw;

  }

  & .guest-rooms-content {
    height: calc(100vh - 19vw);
    overflow: auto;
    //background-color: #75ba38;

    & .room-info {
      display: flex;
      justify-content: center;
      margin: 0.5vw 0 1vw 0;

      & div {
        margin: 0 1vw;
      }

      & .description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: 0.6vw;
        line-height: 0.9vw;
        //margin: 0.3vw auto 0.5vw auto;

        & .selector {
          color: #5f6e7b;
        }
      }

      & .status {
        margin: 0 0 0.6vw 0;
        font-size: 0.8vw;

        & .sub-title {
          font-size: 0.6vw;
          color: #5f6e7b;
        }

        & .black-text {
          margin-left: 1vw;
          font-weight: 700;
          //属性svg
          & .type-icon {
            height: 0.8vw;
            width: 0.8vw;
            display: inline-block;
            position: relative;
            margin-right: 0.1vw;
            top: 0.1vw;
          }
        }
      }

    }

    & .rooms {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 5vw;
    }
  }

}