.generic-modal-root {
  & .modal-content-root {
    width: 35vw;
    min-height: 16vw;
    //max-height: 100vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 0.6vw;
    outline: none;
    //padding为了抵消标题高度
    padding-top: 3.5vw;
    transition: height 300ms;

    & .title-image {
      position: absolute;
      pointer-events: none;
      background-size: contain;
      background-repeat: no-repeat;

      &.title-image1 {
        top: -2vw;
        left: 4vw;
        width: 30vw;
        height: 11vw;
        background-image: url("./assets/1.webp");
      }

      &.title-image2 {
        top: -5.5vw;
        left: -2.5vw;
        width: 18vw;
        height: 11vw;
        background-image: url("./assets/alert.webp");
        display: flex;
      }
      &.title-image3 {
        top: -5vw;
        left: -2vw;
        width: 16vw;
        height: 10vw;
        background-image: url("./assets/logo_512.webp");
        display: flex;
      }
      &.title-image4 {
        top: -5vw;
        left: -3vw;
        width: 11vw;
        height: 11vw;
        background-image: url("./assets/house_icon.webp");
        display: flex;
      }
    }

    //整个modal的大标题
    & .title {
      position: absolute;
      top: -0.2vw;
      left: 9vw;
      font-size: 1.5vw;
      font-family: 'Noto Serif SC', serif;
      font-weight: 900;
    }

    //正文内容container
    & .content-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 2vw;

      //条件容器。附带背景光
      & .conditions-container {
        display: flex;
        width: 100%;
        flex-direction: column;
        text-align: center;
        background-size: 20vw;
        background-repeat: no-repeat;
        background-position: top center;

        &.yes {
          background-image: url("assets/backlight_green.webp");

        }

        &.no {
          background-image: url("assets/backlight_red.webp");

        }
      }

      //条件检查的通用样式
      //示例：建筑不是一座合并后的建筑。
      & .condition {
        margin-left: 1vw;
        font-size: 0.8vw;
        color: #5f6e7b;
      }

      //建材渲染的flex
      & .resources-container {
        display: flex;
        flex-wrap: wrap;
        margin:0.3vw 0 1vw 0;

        & .resource {
          display: flex;

          & .resource-text {
            width: 4vw;

            & .up {
              //font-weight: 500;
              font-size: 1.5vw;
              line-height: 1.5vw;

            }

            & .down {
              font-size: 0.7vw;
            }
          }
        }
      }

      & .MuiDivider-root {
        margin-top: 1vw;
        font-weight: 700;
      }

      //按钮
      & .MuiButton-root {
        margin-top: 2vw;
        max-height: 2vw;
        min-height: 2vw;
        max-width: 6vw;
        min-width: 6vw;
        font-size: 0.8vw;
        padding:0.6vw 0.5vw;
      }

      //额外style
      & .sub-title {
        font-family: 'Noto Serif SC', serif;
        font-weight: 700;
        color: #5f6e7b;
      }

      & .value-info {
        margin-left: 0.5vw;
        //flex:10vw;

        & .value {
          font-size: 1.8vw;
          line-height: 2.2vw;
          //font-weight: 500;
          white-space: nowrap;
        }

        & .name {
          font-size: 0.9vw;
          white-space: nowrap;
        }
      }

      & .orange-button {
        margin: 1vw 0 1vw 0;
        padding: 0.3vw;
        white-space: nowrap;
        width: auto;
      }
    }

    & .progress-dots-root {
      margin-bottom: 1.5vw;
    }
  }
}