.add-photo-avt-root{
  position: relative;
  width: fit-content;
  overflow: hidden;

  & .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0.7;
    transition: opacity 200ms ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    & .MuiSvgIcon-root {
      font-size: 2vw;
    }

    & .add-photo {
      font-size: 0.6vw;
    }
  }

  & .add-photo-pfp-avt {
  }
}