.skills-modal-root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;

  & .banner {

  }

  & .main-container {
    display: flex;

    & .left-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 15vw;
      height: 30vw;
      //background-color: #303030;

      & .filters-container {
        height: 1vw;
        width: 80%;
        box-sizing: border-box;
        display: flex;
        justify-content: end;

        &::before {
          content: "";
          height: 1.2vw;
          width: 100%;
          background-color: #d1d1d1;
        }

        //左侧顶部筛选器
        & .filter {
          width: 1.2vw;
          height: 1.2vw;
          margin: 0 0 0 0.4vw;
          font-size: 0.7vw;
          border-radius: 0.1vw;
          background-color: #d1d1d1;
          color: black;
          transition: background-color 200ms, color 100ms;

          &:hover {
            background-color: #303030;
            color: #d1d1d1;
          }
        }
      }

      & > .list {
        height: 95%;
        max-height: 95%;
        overflow-y: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }

        //左侧列表按钮
        & .l-list-button-root {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: end;
          width: 13vw;
          height: 4.5vw;
          padding: 0 1vw;
          margin: 0.5vw 0.1vw;
          box-sizing: border-box;
          background-size: contain;
          cursor: pointer;
          left: -1.5vw;
          transition: left 200ms;

          &:hover {
            left: -1vw;
          }

          &.active {
            background-image: url("assets/opt1.webp");
            left: 0.2vw;

            & .l {
              color: black;
            }
          }

          & .l {
            text-align: end;
            font-family: 'Noto Serif SC', serif;
            color: white;
            font-size: 1.3vw;
            line-height: 1.2vw;
            font-weight: 900;
            margin-right: 0.7vw;

            & > .title {

            }

            & .level {

            }
          }

          & > img {
            width: 2.8vw;
            height: 2.8vw;
            border-radius: 50%;
            border: 0.3vw solid #d1d1d1;
          }
        }
      }

      & > .filler-bar {
        height: 0.6vw;
        width: 80%;
        background-color: #d1d1d1;
      }
    }

    & .middle-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 14vw;
      height: 36vw;
      margin: 0 0.5vw;
      border-radius: 0.2vw;
      background-image: url("assets/bg3.webp");
      background-size: contain;
      background-repeat: no-repeat;

      & > .title {
        display: flex;
        align-items: center;
        position: relative;
        top: -1vw;
        left: -0.4vw;
        height: 2.3vw;
        width: 100%;
        padding-left: 1vw;
        font-weight: 700;
        background-image: url("assets/tt1.webp");
        background-size: contain;
        background-repeat: no-repeat;
        color: #c5c5c5;
        font-family: 'Noto Serif SC', serif;

        &.title2 {
          margin-top: 1vw;
        }
      }

      //中间列主技能小字
      & .text{
        text-align: center;
        margin: 1vw 0 -1vw 0;
        font-size: 0.8vw;
        color: grey;
      }
      & .deco2 {
        height: 1vw;
        width: 11vw;
        margin: 1.6vw 0 0 0;
        background-image: url("assets/deco2.webp");
        background-size: contain;
        background-repeat: no-repeat;
      }

      & .minor-skills-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 21vw;
        max-height: 21vw;
        margin-top: -1vw;
        padding-top: 1vw;
        overflow-y: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }

        & > .container {
          position: relative;
          //中间列附属技能小字
          & .text{
            text-align: center;
            margin: 1vw 0 -1.5vw 0;
            font-size: 0.8vw;
            color: grey;
          }
        }

        & > :nth-child(odd) {
          left: -2.5vw;
        }

        & > :nth-child(even) {
          left: 2.5vw;
        }
      }

      //中间列按钮
      & .m-list-button-root {
        position: relative;
        cursor: pointer;
        margin-bottom: -0.7vw;
        transform: scale(1);
        transition: transform 200ms;

        &::before {
          position: absolute;
          left: -0.25vw;
          top: -0.25vw;
          content: "";
          width: 4vw;
          height: 4vw;
          border-radius: 50%;
          background-color: transparent;
          transition: box-shadow 200ms, background-color 200ms;
          box-shadow: 0 0 0 0 white;
        }

        &:hover {
          &::before {
            background-color: white;
            box-shadow: 0 0 0.2vw 0 white;
          }
        }

        &.active {
          transform: scale(1.1);

          &::before {
            position: absolute;
            left: -0.25vw;
            top: -0.25vw;
            content: "";
            width: 4vw;
            height: 4vw;
            border-radius: 50%;
            background-color: white;
            box-shadow: 0 0 0.7vw 0.2vw white;
          }

        }

        & .skill-image-root {

        }
      }
    }

    //右边列
    & .right-detail {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 22.5vw;
      height: 29.5vw;
      background-color: #303030;
      margin: 1vw 0;
      border-radius: 0.2vw;
      background-image: url("assets/bg2.webp");
      background-size: contain;
      overflow: hidden;

      & .icon-image-skill {
        width: 4.8vw;
        height: 4.8vw;
        border-radius: 50%;
        border: solid 0.1vw #99732a;
        margin-top: 1.4vw;
      }

      & > .title {
        width: 12vw;
        height: 2.3vw;
        margin: 0.7vw 0 0 0;
        background-image: url("assets/tt2.webp");
        background-size: contain;
        background-repeat: no-repeat;
        font-family: 'Noto Serif SC', serif;
        font-size: 1.1vw;
        font-weight: 700;
        line-height: 2vw;
        text-align: center;
      }

      & > .description {
        font-size: 0.7vw;
        color: #c5c5c5;

      }

      & .skill-progress-bar-root {
        width: 16vw;
        //background-color: #75ba38;
        margin: 0.5vw 0 0 0;

        & .bar-container {
          display: flex;
          align-items: center;
          position: relative;
          height: 4.5vw;
          width: 16vw;
          overflow: hidden;

          & > .texts {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            height: 4vw;
            color: #828282;
            font-size: 0.75vw;
            transform: translateX(-50%);
            white-space: nowrap;

            & > .number {

            }

            & > .text {

            }
          }

          & .level-text {
            position: absolute;
            color: #828282;
            font-size: 0.75vw;
            margin: 0 50%;
            bottom: 0.25vw;
            transform: translateX(-50%);

          }

          & .rare-dashes-container {
            display: flex;
            position: absolute;
            align-items: center;

            & .rare-dash {
              position: relative;
              height: 1.4vw;
              width: 0.1vw;
              background-color: grey;
            }

            & :nth-child(5n+1) {
              height: 1.8vw;
            }
          }

          & .bar-mask {
            position: absolute;
            height: 0.9vw;
            width: 100%;
            overflow: hidden;

            & .bar {
              position: relative;
              height: 100%;
              width: 100%;
              background-image: linear-gradient(to right, #99732a 40%, #d8bf60 50%, #828282 50.5%);
            }

            & .blocked {
              background-image: linear-gradient(to right, #992a2a 40%, #ff0c0c 50%, #828282 50.5%);
            }
          }

          & .front-dashes-container {
            display: flex;
            position: absolute;

            & .front-dash {
              position: relative;
              height: 1.8vw;
              width: 0.3vw;
              background-color: white;
              transform: translateX(-50%);
            }
          }
        }
      }

      & .texts-container {
        display: flex;
        justify-content: space-between;
        width: 13.5vw;
        margin-bottom: 1.5vw;

        & > div {
          font-size: 2vw;
          color: white;
          font-family: 'Noto Serif SC', serif;
          font-weight: 700;
        }
      }

      & .education-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 11vw;
        max-height: 11vw;
        background-image: url("assets/bg4.webp");
        background-size: cover;
        box-shadow: inset 0 0.3vw 0.7vw -0.5vw rgba(0, 0, 0, 0.7);

        & > .title {
          margin: 1.2vw 0 0.3vw 0;
          font-size: 0.75vw;
          color: #828282;

        }

        & .tokens-container-root {
          display: flex;
          justify-content: center;

          & .token-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 0.6vw;

            & > img {
              width: 1.4vw;
              height: 1.4vw;
              object-fit: contain;
            }

            & .indicator {
              width: 1.5vw;
              height: 2vw;
              background-image: url("assets/deco1.webp");
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
        }

        & .education-description {
          width: 16vw;
          margin: 1vw 0 0 0;
          color: #c5c5c5;
          font-size: 0.75vw;
        }

        & .footer {
          position: absolute;
          opacity: 0.5;
          bottom: 0.5vw;
          left: 0.8vw;
          font-size: 0.75vw;
          color: #828282;

        }
      }
    }
  }
}
