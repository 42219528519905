.sell-building-root{
  margin:1vw 2vw 2vw 4vw;

  & .starter-text {
    margin-right: 0.5vw;
    font-size: 0.8vw;
    font-weight: 700;
  }

  & .side {
    display: flex;
    align-items: center;
  }

  & .provide-item {
    display: flex;
    align-items: center;
    width: 17vw;

    & .MuiTextField-root {
      width: 13vw;
    }
  }

  & .required-item {
    text-align: left;
    margin-left: 0;
    width: 15vw;
    & .down{
      white-space: nowrap;
    }
  }

  & .price {
    width: 13vw;
  }

  & .emphasis {
    color: #ed6c02;
    font-weight: 700;
  }

}