
.transaction-root {
  height: calc(100vh - 5vw);
  position: relative;
  padding-left: 2vw;
  display: flex;
  //flex-direction: row;

  //通用div小按钮
  & .button {
    font-size: 0.9vw;
    line-height: 1.15vw;
    cursor: pointer;
    border: 0.1vw solid #ed6c02;
    color: #ed6c02;
    border-radius: 0.2vw;
    padding: 0 0.5vw;

    &:hover {
      background-color: rgba(237, 108, 2, 0.1);
    }
  }

  //两个mui select 的fix
  & .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
    //width: 42vw;
  }

  & .MuiSelect-select {
    padding: 0.2vw 3.2vw 0.2vw 1vw;
  }

  & .trans-left-list {
    flex: 0.8;
    height: 100%;

    & .title-line {
      display: flex;
      justify-content: space-between;

      & .title {
        font-size: 1.5vw;
        color: black;
        //opacity: 0.75;
        font-family: 'Noto Serif SC', serif;
        font-weight: 900;
        text-align: left;
        margin: 1vw 2vw 0 2vw;
      }

      & .add {
        min-height: 2.2vw;
        min-width: 2.2vw;
        margin: 0.8vw 1vw 0.3vw 0;
        padding: 0.6vw 0.8vw;
        font-size: 0.8vw;
        color: black;
        border-color: black;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    & .list-title-line {
      display: flex;
      justify-content: right;
      margin-right: 2vw;

      & .list-title-text {
        text-align: right;
        width: 6vw;
        color: #596a76;
        font-size: 0.8vw;
      }
    }

    & .transaction-list {
      overflow: auto;
      max-height: calc(100vh - 10.5vw);

      & .transaction-bullet {
        border-right: 0.3vw solid white;

        &.nowViewing {
          border-right: 0.3vw solid #ed6c02;
        }
      }

      & .MuiListItemButton-root {
        padding: 0.3vw 1vw 0.3vw 0.8vw;
      }
    }
  }

  & .right-content {
    flex: 1;
    position: relative;

    & .right-container {


      & .trade-container {
        position: relative;
        z-index: 2;
        width: 33vw;
        height: 40vw;
        margin: 4vw auto;
        box-shadow: 0 0.2vw 0.6vw rgba(0, 0, 0, 0.3);
        background-color: white;
        border-radius: 0.2vw;
        padding: 1.5vw;
        overflow: auto;

        opacity: 1;
        transition: opacity 100ms;

        &.hide {
          opacity: 0;
        }

      }

      & .letter-bg {
        position: absolute;
        top: 1.5vw;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 37vw;
        height: 50vw;
        background-image: url("./assets/letter.webp");
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 1;
      }
    }
  }

  //  设置交易的物品
  & .edit-item-bullet {
    display: flex;
    margin-bottom: 0.3vw;

    & .quantity {

      margin: 0 0.5vw 0 0;
      padding: 0;

      & .MuiInputBase-input {
        padding: 0;
        //min-width: 9.5vw;
        //max-height: 1.25vw;
        height: 1.8vw;
        overflow: visible;
        //color: white;
        //font-size: 1vw;
        //border-color: white;
      }

    }
  }
}