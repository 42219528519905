.bazaar-items-history-root {
  margin: 0.5vw 1vw 0 0;
  //overflow: auto;

  & .bazaar-item-bullet-root {
    position: relative;

    & .bazaar-item-seller-and-others {
      display: flex;
      width: 100%;
      align-items: center;
      & .status{
        width: 5vw;
        margin-left: 0.7vw;
        //justify-self: flex-end;
      }
    }

  }
}



