.status-bar {
  height: 1vw;
  position: relative;

  & .type-icon {
    height: 100%;
    display: inline-block;
    margin-right: 0.3vw;
    //filter: drop-shadow(0 0 0.15vw #222);
    //-webkit-filter: drop-shadow(0 0 0.15vw #222);
  }

  & .progress-container {
    border-left: 0.15vw solid rgba(0, 0, 0, 0.5);
    position: relative;
    height: 75%;
    top: -15%;
    display: inline-block;
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
    box-shadow: 0 0 0.3vw rgba(0, 0, 0, 0.7);
    overflow: hidden;

    & .progress-bar {
      position: relative;
      height: 100%;
      transition-duration: 500ms;
    }

    & .changingPreview {
      position: absolute;
      height: 100%;
      transition-duration: 500ms;
      top: 0;
      z-index: 5;
    }

    & .text {
      position: absolute;
      width: fit-content;
      top: 50%;
      transform: translateY(-55%);
      z-index: 9;
      font-family: 'Noto Sans SC', sans-serif;
      font-weight: 500;
      font-size: 0.7vw;
      padding: 0 0.3vw;
      transition-duration: 500ms;
      white-space: nowrap;
    }
  }

  & .change-indicator {
    position: absolute;
    border: 0.1vw solid rgba(0, 0, 0, 0.5);
    height: 1vw;
    width: 0.06vw;
    background-color: white;
    border-radius: 999px;
    top: 0;
    z-index: 7;
  }
}

.status-bar2x2 {
  position: relative;
  //top: -1vw;
  //margin-left: 0.5vw;
}

._2x2column {
  display: inline-block;
}

._2x2single {
  margin: 0.5vw 0.4vw;
}

.status-bar1x4 {
  position: relative;
  //margin-left: 0.5vw;
}

._1x4single {
  margin: 0.1vw 0;
}