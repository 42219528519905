.item-selector-root {
  position: relative;
  display: flex;
  align-self: center;
  border-radius: 0.15vw;
  border: 0.15vw solid rgba(0, 0, 0, 0);
  outline: 0.2vw solid #ed6c02;

  & .flex-box {
    display: flex;
    cursor: pointer;
  }

  & .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    //border-radius: 0.4vw;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0.7;
    transition: opacity 200ms ease-in-out;
    cursor: pointer;
    text-align: center;

    &:hover {
      opacity: 1;
    }

    & .MuiSvgIcon-root {
      font-size: 1.5vw;
    }

    & .description-text {
      font-size: 0.6vw;
    }
  }

  //& .MuiSvgIcon-root{
  //  color: white;
  //  position: absolute;
  //  left: 50%;
  //  transform: translateX(-50%);
  //  align-self: center;
  //  filter: drop-shadow(0 0 0.15vw #222);
  //  -webkit-filter: drop-shadow(0 0 0.15vw #222);
  //
  //}
}