.building-main-root {
  text-align: left;
  /*display: flex;*/
  /*overflow-y: scroll;*/
  /*overflow-x: visible;*/
  height: 95vh;
  position: relative;
  padding-left: 2vw;

  & .c-district {
    display: flex;
    /*overflow-y: scroll;*/
    /*overflow-x: visible;*/
    height: 95vh;
    position: relative;
    padding-left: 2vw;
  }


  & .top-container {
    display: flex;
    height: 10.5vw;
    max-height: 10.5vw;
    border-bottom: rgba(0, 0, 0, 0.12) 0.1vw solid;

    & .building-top-container-back-icon {
      flex: 1.2;
      position: relative;

      & .MuiIconButton-root {
        position: relative;
        top: 1.6vw;
        left: 1.6vw;

        & .MuiSvgIcon-root {
          font-size: 3.5vw;
          color: grey;

        }
      }
    }

    & .building-top-container-avatar {
      flex: 1;
      position: relative;
      //padding: 2vw 1.3vw 0 0;
      padding-top: 2vw;
      display: flex;
      flex-direction: column;
      //justify-content: center;
      align-items: center;
      //top: 2vw;
      //left: 1.3vw;

      //出售标志
      & .MuiButton-root {
        padding: 0;
        width: 3vw;
        min-width: 3vw;
        height: 3vw;
        margin-top: 0.5vw;
      }
    }

    & .building-top-container-building-topic-infos {
      flex: 9.8;
      color: black;

      & .location-text {
        font-family: 'Noto Sans SC', sans-serif;
        color: #596a76;
        -webkit-transform-origin-x: 0;
        transform: scale(0.5);
        font-size: 1.5vw;
        line-height: 1.5vw;
        margin: 1.6vw 0 0 0;

        & .region-text {
          font-family: 'Noto Sans SC', sans-serif;
          background-color: #75ba38;
          font-size: 1.2vw;
          color: white;
          padding: 0.4vw 0.6vw;
        }

        & .region-status-text {
          font-family: 'Noto Sans SC', sans-serif;
          font-size: 1.2vw;
          border: #75ba38 solid 0.2vw;
          padding: 0.2vw 0.4vw;
        }

      }

      & .building-name {
        font-family: 'Noto Sans SC', sans-serif;
        font-size: 1.5vw;
        font-weight: 700;
        line-height: 1.3vw;
        margin-bottom: 0;
        margin-top: 0;

        & .building-dupes {
          color: #596a76;
        }

      }


      & .type-owner-manager {
        -webkit-transform-origin-x: 0;
        font-size: 1.4vw;
        font-family: 'Noto Sans SC', sans-serif;
        transform: scale(0.5);
        line-height: 0.5vw;
        margin: 0.6vw 0 0 0;
        color: #596a76;
      }


      & .status-table {
        color: #596a76;
        font-family: 'Noto Sans SC', sans-serif;
        margin: 1vw 0;


        & .status-table-status {
          display: flex;
          font-size: 1.25vw;
          line-height: 1.25vw;

          & div {
            width: 4.5vw;
          }
        }

        & .status-table-category {
          display: flex;
          font-size: 0.75vw;
          line-height: 0.75vw;

          & div {
            width: 4.5vw;
          }
        }
      }


    }

  }

  & Link {
    color: black;
  }

  & .link {
    color: #596a76;
  }


  & .detail-page {
    display: flex;
    width: 100%;
    height: calc(100vh - 15vw);
    padding: 0;
    margin: 0;

    & .detail-page-left {
      flex: 17.8vw;
      height: 70vw;
      margin: 0;
      background-image: url("../assets/building_bluredbg.webp");
      background-size: contain;
      background-repeat: no-repeat;
    }

    & .flowchart-container {
      width: 15.8vw;
      height: calc(100vh - 19vw);
      position: absolute;
      top: 13.3vw;
      left: 4vw;
      overflow-y: auto;
      padding-left: 2vw;
      padding-right: 1.8vw;
      /*z-index: 200;*/
    }

    & .flowchart-container::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }

    & .detail-page-mid {
      flex: 40.8vw;
      max-width: 40.8vw;
      height: 100%;
      margin-top: 1vw;
      border-left: rgba(0, 0, 0, 0.12) 0.1vw solid;
      border-right: rgba(0, 0, 0, 0.12) 0.1vw solid;
    }

    & .detail-page-right {
      flex: 12.3vw;
      max-width: 12.3vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 3.5vw;

      & > div {
        margin-bottom: 1vw;
      }

      & .architect {
        font-size: 0.7vw;
        color: #5f6e7b;
      }

      & .remarks {
        width: 13vw;
        height: 12.2vw;
        max-height: 12.2vw;
        padding: 0.5vw 0.8vw;
        background-image: url("./assets/building_remarks.webp");
        background-size: contain;
        background-repeat: no-repeat;
        filter: drop-shadow(0 1vw 0.4vw rgba(0, 0, 0, 0.2));
        -webkit-filter: drop-shadow(0 1vw 0.4vw rgba(0, 0, 0, 0.2));
        font-family: 'Noto Serif SC', serif;
        color: #4c2600;
        font-size: 0.9vw;
      }
    }

  }


  & .right-up-image {
    width: 38vw;
    height: auto;
    position: absolute;
    right: -3vw;
    top: -5vw;
    pointer-events: none;
  }

  /*.left-down-image{*/
  /*    width: 38vw;*/
  /*    height: auto;*/
  /*    position: absolute;*/
  /*    right: -4.9vw;*/
  /*    top: 0.3vw;*/
  /*}*/


}
