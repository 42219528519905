.bazaar-items-root {


  & .filters-container {
    & .MuiButton-root {
      padding: 0 0.5vw;
      margin: 0.3vw;
      max-width: 9vw;
      //width: 2.7vw;
      min-width: 2.7vw;
      max-height: 2.5vw;
      min-height: 2.5vw;
      font-size: 1vw;
    }
  }

  & .sort-container {
    position: absolute;
    //margin: 0.4vw 0 0.2vw 18vw;
    top:4.4vw;
    margin-left: 18vw;
    width: 100%;
    text-align: center;
    font-family: 'Noto Serif SC', serif;
    font-size: 0.9vw;
    font-weight: 700;
    color: #5f6e7b;

  }

  & .goods-list-container {
    height: calc(100vh - 12vw);
    overflow: auto;

  }
}
