.overview-buildings-render {
  display: flex;
  flex-direction: column;
  justify-content: center;

  & .list-item {
    justify-content: space-between;
    align-items: center;
    padding: 0.3vw 2vw 0.3vw 4.5vw;

    &.terra {
      padding-right: 3vw;
    }

    & .form-maker {
      color: #eaeaea;
      font-size: 2.3vw;
      line-height: 1.9vw;
      margin-right: 1vw;
      transform: scale(1, 1.4);
    }

    & .inLineSVG-root {
      margin-right: 0.3vw;
    }

    & .avt {
      margin: auto 0.3vw auto 0;
    }

    &.owner-name {
      text-align: right;
      margin: auto 1.5vw auto auto;
    }

    &.area-taken {
      margin-right: 1vw;
    }

    //
    ////单个街区列表里的
    //& .two-story-text {
    //  color: #5a6a77;
    //  margin: auto 0;
    //
    //  & .up {
    //    font-size: 1.25vw;
    //    line-height: 1.2vw;
    //    margin: 0.4vw 0 0.2vw 0;
    //  }
    //
    //  & .down {
    //    font-size: 0.7vw;
    //  }
    //}
  }
}