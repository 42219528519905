.building-flow-chart-link {
    width: 11.4vw;
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 12px;
    line-height: 2px;
    text-decoration: none;
    color: white;
    font-weight: bolder;
    padding-left: 1.6vw;
    padding-top: 0.5vw;
    -webkit-filter: drop-shadow(0 0.5vw 0.5vw  rgba(0,0,0,0.3));
    /*filter: drop-shadow(5px 5px 5px #222);*/
}


.building-flow-chart-left-down {
    font-size: 1vw;
    line-height: 0.4vw;
    margin: 0;
    -webkit-transform-origin-x: 0;
    transform: scale(0.5);
    font-weight: 500;
}



.building-flow-chart-facilities {
    height: 3.45vw;
    background-image: url("assets/building_tab_2.webp");
    transition: all 100ms;
}

.building-flow-chart-facilities-active {
    margin-left: 2vw;
    height: 3.45vw;
    color: black;
    background-image: url("assets/building_tab_1.webp");
    transition: all 100ms;
}
.building-flow-chart-smallInOut-1 {
    height: 3.45vw;
    background-image: url("assets/building_tab_14.webp");
    transition: all 100ms;
}

.building-flow-chart-smallInOut-1-active {
    margin-left: 2vw;
    height: 3.45vw;
    color: black;
    background-image: url("assets/building_tab_13.webp");
    transition: all 100ms;
}

.building-flow-chart-smallInOut-2 {
    height: 3.45vw;
    background-image: url("assets/building_tab_12.webp");
    transition: all 100ms;
}

.building-flow-chart-smallInOut-2-active {
    margin-left: 2vw;
    height: 3.45vw;
    color: black;
    background-image: url("assets/building_tab_11.webp");
    transition: all 100ms;
}

.building-flow-chart-tools {
    position: relative;
    height: 2.7vw;
    background-image: url("assets/building_tab_4.webp");
    transition: all 100ms;
}

.building-flow-chart-tools-active {
    margin-left: 2vw;
    position: relative;
    height: 2.7vw;
    color: black;
    background-image: url("assets/building_tab_3.webp");
    transition: all 100ms;
}

.building-flow-chart-works {
    position: relative;
    padding-top: 2.2vw;
    height: 4.8vw;
    width: 11.3vw;
    background-image: url("assets/building_tab_6.webp");
    margin: 0;
    color: white;
    transition: all 100ms;
}

.building-flow-chart-works-active {
    margin-left: 2vw;
    background-image: url("assets/building_tab_5.webp");
    color: black;
    transition: all 100ms;
    font-family: 'Noto Serif SC', serif;
}


.building-flow-chart-img {
    position: relative;
    height: 7vw;
    width: 13vw;
    background-image: url("assets/building_tab_6.webp");
    padding: 0;
    margin: 0;
}


.building-flow-chart-text {
    position: absolute;
    top: 0.5vw;
    left: 5vw;
    font-size: 1.2vw;
    transition: all 100ms;
}

.building-flow-chart-left{
    position: absolute;
    top: 2vw;
    left: 1vw;
    font-size: 1.2vw;
    transition: all 100ms;
}

.building-flow-chart-left-up {
    font-family: 'Noto Serif SC', serif;
    font-size: 1.2vw;
    line-height: 1.5vw;
    margin: 0;
    font-weight: 300;
}

.building-flow-chart-right {
    font-family: 'Noto Serif SC', serif;
    margin-top: 0.4vw;
    line-height: 1.2vw;
    font-size: 1.2vw;
    transition: all 100ms;
}
.building-flow-chart-supervisor {
    position: relative;
    padding-top: 2.2vw;
    height: 4.8vw;
    background-image: url("assets/building_tab_8.webp");
    margin: 0;
    transition: all 100ms;
}

.building-flow-chart-supervisor-active {
    position: relative;
    padding-top: 2.2vw;
    background-image: url("assets/building_tab_7.webp");
    margin-left: 2vw;
    color: black;
    transition: all 100ms;
}

.building-flow-chart-output {
    position: relative;
    height: 2.7vw;
    background-image: url("assets/building_tab_10.webp");
    margin: 0;
    transition: all 100ms;
}

.building-flow-chart-output-active {
    position: relative;
    height: 2.7vw;
    color: black;
    background-image: url("assets/building_tab_9.webp");
    transition: all 100ms;
    margin-left: 2vw;
}
.building-flow-chart-status1x4{
    position: absolute;
top: 1vw;
    left:3.6vw;
}