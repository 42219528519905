.hire-managers-root {
  margin: 1vw 2vw 2vw 4vw;

  & .current-manager-container {
    display:inline-flex;
    align-items: center;
    padding: 0.2vw 0.5vw 0.2vw 0.2vw;

    & > .r {
      text-align: left;

      & > .d {
        font-size: 0.8vw;
        color: #5f6e7b;
      }
    }

    //管理员 玩家选择器
    &.select-manager {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 100ms;
      border: solid 0.2vw white;
      outline: solid 0.2vw #ed6c02;
      border-radius: 0.2vw;

      &:hover {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}