.room-card-root {
  position: relative;
  margin: 0.5vw 0.6vw;

  & .avt {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 3vw;
    height: 3vw;

    &.seal {
      width: 3.9vw;
      height: 3.9vw;
      left: -0.45vw;
    }
  }

  & .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 10vw;
    max-width: 10vw;
    height: 6.5vw;
    max-height: 6.5vw;
    margin-left: 1.5vw;
    border-radius: 0.2vw;

    //自己
    &.solid {
      border: 0.1vw solid rgba(0, 0, 0, 0.25);
      transition: border 100ms;
      cursor: pointer;
      &:hover{
        border: 0.1vw solid rgba(0, 0, 0, 1);
      }
      //防误触
      & .avt {
        pointer-events: none;
      }

    }

    &.dashed {
      border: 0.15vw dashed rgba(0, 0, 0, 0.25);

    }

    &.float {
      border: #eaeaea 0.1vw solid;
      box-shadow: 0 0.2vw 0.6vw rgba(0, 0, 0, 0.2);
      transition: box-shadow 100ms;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0.2vw 1.2vw rgba(0, 0, 0, 0.2);
      }

    }

    & > div {
      margin-left: 1.8vw;
    }

  }

  &.guest-card-root {
    & .name {
      font-size: 1.1vw;
      line-height: 1.5vw;
      color: #5a6a77;
      //等着删掉
      //margin-top: 1.9vw;
    }

    & .description {
      font-size: 0.7vw;
      color: #5f6e7b;
      line-height: 1.5vw;
    }
    & .condition {
      display: flex;
      align-items: center;
      color: #5f6e7b;

      & * {
        font-size: 0.8vw;
      }
    }
  }

  &.contract-card-root {
    & .card-body {
      & > div {
        font-size: 0.85vw;
      }

      & .contract-orange {
        color: #ff8742;
        font-size: 0.75vw;
        font-weight: 700;
        //margin-bottom: 0.75vw;
      }

      & .flex {
        display: flex;

        & .price {
          & .price-number {
            font-size: 1.1vw;
            font-weight: 700;
          }

          & .text {
            font-size: 0.7vw;
            color: #5f6e7b;

          }
        }
      }

      & .condition {
        display: flex;
        align-items: center;
        font-weight: 700;
        color: #5f6e7b;

        & * {
          font-size: 0.8vw;
        }
      }

      //& div:nth-child(2) {
      //  //font-weight: 400;
      //}
      //
      //& div:nth-child(3) {
      //
      //}
      //
      //& div:nth-child(4) {
      //  font-size: 0.55vw;
      //  line-height: 1vw;
      //}
      //& .heavy{
      //  //font-weight: 700;
      //}
    }
  }
}