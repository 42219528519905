.notif-actions-root{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  //margin: -0.5vw 0;
  & .MuiButton-root.MuiButtonBase-root{
    padding: 0.2vw 1vw;
    margin: 0.3vw 0;
    width: 5vw;
  }
}