
.restaurant-edit-menu-root {
  & .restaurant-edit-menu-title {
    position: relative;
    font-size: 1.4vw;
    font-family: 'Noto Serif SC', serif;
    font-weight: 900;
    margin: 0 3vw 0 4vw;
    display: flex;
    align-items: center;

    & .my-luxury {
      font-family: 'Noto Sans SC', sans-serif;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 2vw;

      & .t {
        font-weight: 700;
        font-size: 1.4vw;
        line-height: 1.4vw;
        color: #9c7732;
      }

      & .d {
        font-weight: 500;
        font-size: 0.7vw;
        color: #5f6e7b;
      }
    }

    & .recipe-public {
      margin-left: auto;
      font-size: 0.9vw;
      font-family: 'Noto Sans SC', sans-serif;
      color: #5f6e7b;
      font-weight: 500;
    }

    & .button8 {
      position: relative;
      z-index: 5;
      top: 0.15vw;
      margin-left: auto;
    }
  }

  & .restaurant-edit-menu-content {
    height: calc(100vh - 20vw);
    overflow: auto;

    & .list-item-building {
      width: 34vw;
      padding-left: 7.5vw;
      box-sizing: border-box;
      margin-top: 0.5vw;

      & .selling {
        font-size: 1.2vw;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.38);

        &.active {
          color: #ed6c02;
        }
      }

      //设置价格
      & .starter-text {
        margin-right: 0.5vw;
        font-size: 0.8vw;
        font-weight: 700;
      }

      & .side {
        display: flex;
        align-items: center;
      }

      & .required-item {
        text-align: left;
        margin-left: 0;

        & .MuiTextField-root {
          width: 6vw;
        }
        & .down {
          white-space: nowrap;
        }
      }

      & .price {
        width: 13vw;
      }

      & .emphasis {
        color: #ed6c02;
        font-weight: 700;
      }

    }

    //勾选选项
    & .options {
      display: flex;
      flex-wrap: wrap;
      margin: 0 5vw 1.5vw 4vw;

      & .option {
        margin-left: 4vw;
        font-size: 0.9vw;
        font-family: 'Noto Sans SC', sans-serif;

        &.disabled {
          color: rgba(0, 0, 0, 0.38);
        }

        & .MuiCheckbox-root {
          padding: 0.2vw;
          transform: scale(0.9);
        }

        & .MuiTextField-root {
          width: 4vw;
          margin: 0 0.2vw;
        }
      }
    }

    //删除 编辑 提交 三按钮
    & .actions {
      display: flex;
      justify-content: flex-end;
      margin: 0 1vw 1vw 0;

      & div {
        margin-right: 2vw;
        font-size: 0.8vw;
        cursor: pointer;
      }

      & :nth-child(1) {
        color: #e74652;
        text-decoration: underline;
      }

      & :nth-child(2) {
        color: #5f6e7b;
        text-decoration: underline;
      }

      & .button8 {
        transform: translateY(-0.2vw);
      }
    }
  }
}