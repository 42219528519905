.side-job-root {
  height: calc(100vh - 5vw);
  position: relative;
  padding-left: 2vw;
  display: flex;
  //mui列表按钮
  & .MuiListItemButton-root {
    padding: 0;
  }

  //mui按钮
  & .MuiButtonBase-root:not(.MuiListItemButton-root) {
    margin: 0.25vw 2.5vw;
    padding: 0;
    max-width: 7vw;
    min-width: 6vw;
    max-height: 1.8vw;
    min-height: 2vw;
    font-size: 0.8vw;
    color: white;
    border-color: #ed6c02;
    background-color: #ed6c02;
    font-weight: 700;
  }

  //buff/debuff的那个颜色字
  & .red {
    color: #bd1f1f;
    font-weight: 700;
  }

  & .green {
    color: #75ba38;
    font-weight: 700;
  }

  //选项的那个头图（包括左边列表的和右边头图）
  & .side-job-option-img {
    position: relative;
    width: 4vw;
    height: 4vw;

    & .grab {
      position: absolute;
      left: 0.9vw;
      top: 0.85vw;
      width: 2.5vw;
      height: 2.5vw;
      filter: drop-shadow(0 0.3vw 0.2vw rgba(0, 0, 0, 0.3));
      -webkit-filter: drop-shadow(0 0.2vw 0.25vw rgba(0, 0, 0, 1));
    }
  }

  //特色产品勾边高亮
  & .loot {
    display: inline-block;

    &.right-panel {
      margin-right: 0.5vw;
    }

    &.specialty {
      margin-right: 0.2vw;

      & .item-image-root {
        border-radius: 0.1vw;
        outline: 0.25vw solid #5f6e7b;
      }

      &.civilization-specialty {
        & .item-image-root {
          outline-color: #ed6c02;
        }
      }

      &.city-specialty {
        & .item-image-root {
          outline-color: #9c7732
        }
      }

      &.null {
        & .item-image-root {
          display: none;
        }
      }

      //右侧面板额外的特产物品再加文字描述
      &.right-panel {
        margin-right: 0.5vw;
        display: flex;
        align-items: center;

        &.specialty {

          &::after {
            width: 0.8vw;
            height: 90%;
            padding: 0.25vw 0.1vw 0 0.2vw;
            display: inline-block;
            font-size: 0.6vw;
            line-height: 0.6vw;
            color: white;
            border-bottom-right-radius: 0.2vw;
            border-top-right-radius: 0.2vw;
          }

          &.civilization-specialty::after {
            content: "文明特产";
            background-color: #ed6c02;
          }

          &.city-specialty::after {
            content: "城市特产";
            background-color: #9c7732;
          }
        }
      }
    }


  }

  & .l-root {
    flex: 1.5;

    & .title-line {
      text-align: left;

      & .title {
        font-size: 1.5vw;
        color: black;
        font-family: 'Noto Serif SC', serif;
        font-weight: 900;
        margin: 1vw 2vw 0 2vw;
      }

      & .sub-text {
        margin: 0 2.5vw 0.5vw 2vw;
        color: #596a76;
        font-size: 0.8vw;
      }
    }

    & .option-bullets {
      margin: 0 0 0 1vw;
      height: calc(100vh - 11vw);
      overflow: auto;
      //scrollbar-width: none; /* Firefox */
      //-ms-overflow-style: none; /* IE and Edge */
      //&::-webkit-scrollbar {
      //  display: none;
      //}
      & > :last-child {
        margin-bottom: 5vw;
      }

      & .category-divider {
        font-size: 0.8vw;
        color: #5f6e7b;
      }

      & .option-bullet {
        display: flex;
        justify-content: space-between;
        padding: 0.5vw 1.6vw 0.5vw 1.5vw;
        border-right: 0.3vw solid white;

        &.selected {
          border-right: 0.3vw solid #ed6c02;
        }


        & .l {
          display: flex;

          & .info {
            text-align: left;
            margin-left: 0.8vw;
            max-width: 17vw;

            & .title {
              font-weight: 900;
              color: #46b4e6;
            }

            & .text {
              color: #596a76;
              font-size: 0.6vw;
            }

            & .detail {
              font-weight: 700;
              font-size: 0.8vw;
              width: 30vw;

            }
          }
        }

        & .r {
          text-align: right;
          color: #596a76;
          font-size: 0.6vw;


        }
      }
    }
  }

  //整个右侧
  & .r-root {
    flex: 1.2;
    position: relative;
    height: calc(100vh - 5vw);
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    &::-webkit-scrollbar {
      display: none;
    }

    & .title-illustration {
      position: relative;
      margin: 3vw auto 0 auto;
      width: 26vw;
      height: 6.5vw;
      //box-shadow: 0 0.6vw 0.8vw rgba(0, 0, 0, 0.4);
      filter: drop-shadow(0 0.6vw 0.5vw rgba(0, 0, 0, 0.4));
      -webkit-filter: drop-shadow(0 0.6vw 0.5vw rgba(0, 0, 0, 0.4));
      background-size: contain;
      background-repeat: no-repeat;

      & .side-job-option-img {
        position: relative;
        top: 0.8vw;
        margin: 0 auto;
        -webkit-filter: drop-shadow(0 0.2vw 0.4vw rgba(0, 0, 0, 0.3));

        & .inLineSVG-root {
          background-color: white;
          padding: 0.15vw;
          border-radius: 0.4vw;

        }
      }
    }

    //右侧大标题
    & .side-job-title {
      //font-family: 'Noto Serif SC', serif;
      font-weight: 900;
      font-size: 1.2vw;
      line-height: 2vw;
      position: relative;
      //background-color: #bfbfbf;
      background-image: url("./assets/title.webp");
      background-size: cover;
      background-repeat: no-repeat;
      width: 8vw;
      height: 2vw;
      top: -1vw;
      margin: 0 auto;
      color: white;
      //color: #46b4e6;
      -webkit-filter: drop-shadow(0 0.1vw 0.4vw rgba(255, 255, 255, 0.7));
    }

    //右侧面板所有的分割线
    & .MuiDivider-root {
      margin: 0.7vw 0 0 0;
    }

    //左边儿图片右边双层文字
    & .img-with-double-info {
      display: flex;
      margin-right: 0.5vw;

      & .double-info {
        text-align: left;
        margin-left: -0.3vw;

        & .up {
          font-size: 1.25vw;
          line-height: 1.2vw;
          margin: 0.4vw 0 0.2vw 0;
        }

        & .down {
          font-size: 0.7vw;
          min-height: 1vw;
          color: #5a6a77;
        }
      }
    }

    //右侧主要内容容器
    & .detail-container {
      margin: 0 4vw 2vw 4vw;
      padding: 0 1vw 2vw 1vw;
      border-bottom-right-radius: 0.3vw;
      border-bottom-left-radius: 0.3vw;
      box-shadow: 0 0.4vw 0.6vw rgba(0, 0, 0, 0.25);

      & .info {
        color: #596a76;
        font-size: 0.6vw;
        margin: 0 1vw 0.5vw 1vw;
      }

      & .r-subtitle {
        font-family: 'Noto Serif SC', serif;
        font-weight: 700;
        color: grey;
        font-size: 0.8vw;
        text-align: left;
        margin-bottom: 0.3vw;

        & .MuiSvgIcon-root {
          font-size: 0.8vw;
          position: relative;
          top: 0.1vw;
        }
      }

      & .items-line {
        display: flex;
        flex-wrap: wrap;
        min-height: 3vw;
        margin-left: 1.5vw;

        & .front {
          align-self: flex-end;
          margin-bottom: 0.2vw;
          font-size: 0.8vw;
          font-weight: 700;
        }

        & .loot {

        }

        & .info {
          color: #596a76;
          font-size: 0.6vw;
          margin: 1vw auto 0.5vw auto;

        }

        &.get {
          color: #fd7700;
        }
      }

      & .related-skills {
        display: flex;
        justify-content: space-evenly;

        & .related-skill {

        }
      }


      & .buff-container {
        display: flex;
        flex-direction: column;
        text-align: left;
        //flex-wrap: wrap;
        margin-left: 1.5vw;
        font-size: 0.6vw;
        font-weight: 700;

        & .buff {
        }
      }

      & .status-change {
        margin: 0.5vw 0;
      }
    }
  }

}