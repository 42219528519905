.worker-name,.skill-level,.skill-needed,.working-status,.extra-message{
  pointer-events: none;
}



.work-card  .ink {
    position: absolute;
    z-index: -4;
    width: 12.2vw;
    top: 3vw;
    left: -3vw;
    pointer-events: none;
  //visibility: visible;
  }

.work-card  .ink.card-type-1 {
    visibility: hidden
  }

.work-card  .ink.card-type-2 {
    visibility: hidden
  }
.work-card  .ink.card-type-3.workingStatus_true {
    visibility: hidden;
  }
.work-card  .ink.card-type-3.workingStatus_false {
    //visibility: visible;
  visibility: visible;

}


.work-card  .checkMark {
    position: absolute;
    z-index: 6;
    width: 3.2vw;
    top: 8.8vw;
    left: 1vw;
    pointer-events: none;
  }

.work-card  .checkMark.card-type-1 {
    visibility: hidden
  }

.work-card  .checkMark.card-type-2.workingStatus_true  {
    visibility: visible;
  }
.work-card  .checkMark.card-type-2.workingStatus_false  {
    visibility: hidden;
  }
.work-card  .checkMark.card-type-3.workingStatus_true {
    visibility: visible;
  }
.work-card  .checkMark.card-type-3.workingStatus_false {
    visibility: hidden;
  }

.work-card {
  position: relative;
  width: 6.5vw;
  max-height: 10.2vw;
  z-index: 99;
  text-align: center;
  margin: 0;
  font-size: 1vw;


  & .user-avatar {
    left: 1.8vw;
    z-index: 4;
  }

  & .seal_container {
    position: relative;
    left: 1.8vw;
    z-index: 3;
    width: 3vw;
    height: 3vw;

  }
 & .seal{
   position: relative;
left: -0.5vw;
   top: -0.3vw;
   width: 130%;
   height:130%;
 }



  & .card-body.card-type-1 {
    position: relative;
    min-height: 7vw;
    z-index: 2;
    top: -1.5vw;
    border-radius: 0.2vw;
    border: 0.15vw dashed rgba(0, 0, 0, 0.25);
    padding-top: 1.6vw;
  }

  & .card-type-1 {

    /*不可点击card-type-1*/
    & .worker-name {
      font-size: 0.85vw;
      font-weight: 500;
      line-height: 1em;
      color: rgba(0, 0, 0, 0.3);
      margin-bottom: 0.7vw;
    }

    /*不可点击card-type-1*/
    & .skill-level {
      font-size: 1.5vw;
      font-weight: 400;
      line-height: 1em;
      color: rgba(0, 0, 0, 0.3);
      margin-bottom: 0;
    }

    /*不可点击card-type-1*/
    & .skill-needed {
      font-size: 0.55vw;
      font-weight: 500;
      line-height: 1em;
      color: rgba(0, 0, 0, 0.3);
      margin-bottom: 1vw;
    }

    /*不可点击card-type-1*/
    & .working-status {
      font-size: 1.05vw;
      font-weight: 500;
      line-height: 1em;
      color: rgba(0, 0, 0, 0.3);
      margin-bottom: 0;
    }

    /*不可点击card-type-1*/
    & .extra-message {
      font-size: 0.55vw;
      font-weight: 500;
      line-height: 1em;
      //color: rgba(0, 0, 0, 0.3);
      color: red;
    }

  }

  /*描边普通卡card-type-2*/
  & .card-body.card-type-2 {
    position: relative;
    min-height: 7vw;
    z-index: 2;
    top: -1.5vw;
    border-radius: 0.2vw;
    border: 0.1vw solid rgba(0, 0, 0, 0.25);
    padding-top: 1.6vw;
  }

  & .card-type-2 {
    /*描边普通卡card-type-2*/
    & .worker-name {
      font-size: 0.85vw;
      font-weight: 500;
      line-height: 1em;
      color: #5a6a77;
      margin-bottom: 0.7vw;
    }

    /*描边普通卡card-type-2*/
    & .skill-level {
      font-size: 1.5vw;
      font-weight: 400;
      line-height: 1em;
      color: rgba(0, 0, 0);
      margin-bottom: 0;
    }

    /*描边普通卡card-type-2*/
    & .skill-needed {
      font-size: 0.55vw;
      font-weight: 500;
      line-height: 1em;
      color: #5a6a77;
      margin-bottom: 1vw;
    }

    /*描边普通卡card-type-2*/
    & .working-status {
      font-size: 1.05vw;
      font-weight: 500;
      line-height: 1em;
      color: rgba(0, 0, 0);
      margin-bottom: 0;
    }

    /*描边普通卡card-type-2*/
    & .extra-message {
      font-size: 0.55vw;
      font-weight: 500;
      line-height: 1em;
      //color: #5a6a77;
      color: red;
    }
  }

  /*引导点击 card-type-3*/
  & .card-body.card-type-3, {
    position: relative;
    min-height: 7vw;
    z-index: 2;
    top: -1.5vw;
    border: #eaeaea 0.1vw solid;
    border-radius: 0.2vw;
    background-color: white;
    box-shadow: 0 0.2vw 0.6vw lightgray;
    padding-top: 1.6vw;
  }


  & .card-type-3,{
    /*引导点击 card-type-3*/
    & .worker-name {
      font-size: 0.85vw;
      font-weight: 500;
      line-height: 1em;
      color: #5a6a77;
      margin-bottom: 0.7vw;
    }

    /*引导点击 card-type-3*/
    & .skill-level {
      font-size: 1.5vw;
      font-weight: 400;
      line-height: 1em;
      /*color: #5a6a77;*/
      margin-bottom: 0;
    }

    /*引导点击 card-type-3*/
    & .skill-needed {
      font-size: 0.55vw;
      font-weight: 500;
      line-height: 1em;
      color: #5a6a77;
      margin-bottom: 1vw;
    }

    /*引导点击 card-type-3*/
    & .working-status {
      font-size: 1.05vw;
      font-weight: 900;
      line-height: 1em;
      color: #ff8742;
      margin-bottom: 0;
    }

    /*引导点击 card-type-3*/
    & .extra-message {
      font-size: 0.55vw;
      font-weight: 500;
      line-height: 1em;
      //color: #5a6a77;
      color: red;
    }


  }
}