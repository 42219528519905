.notifications-root {
  position: relative;
  display: flex;
  height: calc(100vh - 5vw);
  padding-left: 2vw;
  //overflow-y: scroll;
 & a {
    color: #095f85;
  }

  & .left-container {
    flex: 1;

    & .title {
      font-size: 1.5vw;
      //color: black;
      //opacity: 0.75;
      font-family: 'Noto Serif SC', serif;
      font-weight: 900;
      text-align: left;
      margin: 1vw 2vw 0 2vw;
    }

    & .MuiTabs-root {
      //display: flex;
      //justify-content: center;
      //padding: 0.5vw 0;

      //& .MuiButton-root {
      //  margin: 0 1vw;
      //}

      //& .MuiDivider-root.MuiDivider-vertical {
      //  height: calc(1.6vw);
      //  margin: 0.4vw 0;
      //
      //}
    }

    & .notifications-container-root {
      height: calc(100vh - 12vw);
      overflow: auto;

      & .notification-container {
        display: flex;
        min-height: 4.2vw;
        padding: 0.5vw 0;
        margin: 0 1.7vw;
        border-bottom: 0.1vw solid rgba(0, 0, 0, 0.12);

        & .icons {
          width: 2vw;
          display: flex;
          flex-direction: column;
          //justify-content: space-between;
          align-items: center;
          margin-bottom: -0.4vw;

          & .circle {
            margin-top: 0.3vw;
            font-size: 0.7vw;
          }

          & .priority-high {
            margin-top: 0.6vw;
            font-size: 0.7vw;
          }

          & .MuiButtonBase-root.MuiIconButton-root {
            margin-top: auto;

            & .flag {
              font-size: 1vw;
              color: #eaeaea;
            }
          }
        }

        & .pfp {
          margin-left: 1vw;

        }

        & .content-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 0.7vw;
          text-align: left;
          width: 20vw;


          & .content {
            font-size: 0.9vw;
          }

          & .info {
            display: flex;
            align-items: center;
            font-size: 0.7vw;
            color: #5f6e7b;

            & .MuiSvgIcon-root {
              font-size: 0.8vw;

            }
          }
        }

        & .actions {
          width: 7vw;
          margin-left: auto;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  & .right-container {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;

    & img {
      width: 6vw;
      height: 10vw;
      object-fit: contain;
      margin-top: 15vw;
    }
  }
}