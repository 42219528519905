.manage-guests-root {
  & .manage-guests-content {
margin: 0 2vw;
    & .guest {
      display: flex;
      align-items: center;
      margin-bottom: 0.8vw;

      & .options {
        max-height: 2vw;
        min-height: 2vw;
        max-width: 3vw;
        min-width: 3vw;
        font-size: 0.8vw;
        color: #ed6c02;
        border-color: #ed6c02;
        background-color: white;
      }
    }
  }
}