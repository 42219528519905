
.App {
  background-color: #303030;
  width: 100%;
  height: 100%;
  background-size: 100%, auto, contain;
  margin: 0;
  position: absolute;
  text-align: center;
  color: #202125;
  overflow: hidden;
  //取消最小字体限制。好像无效了这俩css
  -webkit-text-size-adjust: none;
  text-size-adjust: none;

  & body {
    background-color: #303030;
    height: 100%;
    -webkit-user-drag: none;
  }

  /*上方导航栏*/
  & .App-background {
    background-image: url("assets/main_page_background.webp"),
    url("assets/main_page_background_2.webp");
    background-position: center top, center top;
    background-repeat: no-repeat, repeat-y;
    display: flex;
    position: relative;
    background-size: 98%, 98%;
    width: 97%;
    height: 100%;
    //整体左右微调
    margin: 0 0 0 2vw;

    & .app-left {
      /*左侧列表*/
      & .left-bar {
        position: relative;
        left: 0;
        top: 2vw;
        width: 21vw;
        margin: 0.1vw 0.1vw 0.1vw 0.1vw;

        & .app-ink-icon {
          position: absolute;
          z-index: 2;

          & img {
            height: auto;
            width: 9.6vw;
            transition: transform 100ms;

            &:hover {
              transform: scale(1.06);
            }
          }
        }

        & .food-icon {
          left: 5.2vw;
          top: 4.6vw;
        }

        & .work-icon {
          left: 11.4vw;
          top: 12.2vw;
        }

        & .resident-icon {
          left: 1.6vw;
          top: 15.4vw;
        }

        & .left-icon-ink {
          position: absolute;
          width: 11vw;

          &.ink-food {
            left: 4.7vw;
            top: 5.9vw;
          }

          &.ink-work {
            left: 10.7vw;
            top: 13.2vw;
          }

          &.ink-resident {
            left: 1.1vw;
            top: 16.4vw;
          }
        }

        & .left-list {
          /*background-image: url("assets/main_page_sticker.webp");*/
          position: absolute;
          top: 26vw;
          left: 1.5vw;
          text-align: left;
          width: 20vw;
          height: 20.5vw;
          padding-top: 0;
          background-repeat: no-repeat;
          background-size: cover;
          transition: transform 200ms;
          z-index: 4;

          &::-webkit-scrollbar {
            display: none; /* Chrome Safari */
          }
        }


        & .left-list-bgimg {
          position: absolute;
          z-index: 6;
          width: 100%;
          pointer-events: auto;

        }

        & .left-list-text-container {
          position: relative;
          padding-top: 3.8vw;
          padding-left: 3.3vw;
          pointer-events: auto;

          & .left-list-text {
            position: relative;
            text-decoration: none;
            color: #373229;
            font-size: 1.7vw;
            line-height: 1.8em;
            font-family: 'Noto Serif SC', serif;
            font-weight: 900;
            z-index: 7;
            pointer-events: auto;

            & .unread-dot {
              background-color: #ff8842;
              position: absolute;
              top: 0.3vw;
              right: -0.3vw;
              width: 0.6vw;
              height: 0.6vw;
              border-radius: 50%;

            }

            &.my-notifications {

            }
          }
        }
      }
    }

    & .app-right {
      width: 73vw;
      /*上方导航栏左侧*/
      & .nav-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 5.8vw;

        & .nav-bar-left {
          position: relative;
          left: 0.5vw;
          text-align: left;
          width: 45vw;

          & .nav-button {
            position: relative;
            background-size: contain;
            background-image: url("assets/main_page_nav_link.webp");
            background-repeat: no-repeat;
            background-position: left center;
            width: 12.5vw;
            height: 3.8vw;
            margin-right: 1.5vw;
            border-width: 0;
            text-align: center;
            float: left;
            top: 0;
            transition: top 100ms;

            &:hover {
              top: 0.3vw;
            }


            & img {
              position: relative;
              top: 1.1vw;
              width: 4vw;
              z-index: 21;
            }
          }

          //顶部旗帜
          & .nav-link-flag {
            background-image: url("assets/main_page_sticker.webp");
            background-size: 100%, 100%;
            background-repeat: no-repeat;
            position: absolute;
            top: -5vw;
            width: 9.6vw;
            height: auto;
            z-index: 10;
            pointer-events: none;
            transition: left 150ms;

            &.nav-link-flag-forum {
              left: 1.5vw;
            }

            &.nav-link-flag-bazaar {
              left: 15.4vw;
            }

            &.nav-link-flag-district {
              left: 29.4vw;
            }

            &.nav-link-flag-other {
              visibility: hidden;
              left: 0;
            }
          }
        }

        /*上方导航栏右侧*/
        & .nav-bar-right {
          position: relative;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          //width: 35%;
          margin-right: 1vw;

          & .game-day {
            color: rgba(32, 33, 37, 0.7);
            font-family: 'Noto Serif SC', serif;
            font-weight: 900;
            white-space: nowrap;
            margin-right: 0.5vw;
          }

          & .right-icon {
            position: relative;
            padding-top: 0.2vw;
            margin-left: 0.5vw;
            border-radius: 0.3vw 0.3vw 0 0;
            background-color: rgba(255, 255, 255, 0);
            box-shadow: 0 0.1vw 0.6vw rgba(0, 0, 0, 0);
            transition: background-color 50ms, box-shadow 200ms;


            &.avt {
              height: 4vw;
              display: flex;
              align-items: center;
            }

            &.status-bar2x2 {
              padding-right: 0.2vw;
            }

            &.status-open {
              background-color: white;
              box-shadow: 0 0.3vw 1vw rgba(0, 0, 0, 0.5);
            }
          }
        }

      }

      & .main-body-container {
        position: absolute;
        top: 6vw;
        left: 19.6vw;
        margin-right: 5vw;
        margin-top: -0.2vw;
        min-width: 73.2vw;
        max-width: 73.2vw;
        min-height: 80vw;
        max-height: 80vw;
        border-radius: 1vw 1vw 0 0;

        & .main-body-whitebg {
          position: absolute;
          margin-left: 2vw;
          width: 71.2vw;
          background-color: white;
          height: 100vh;
          border-radius: 1vw 1vw 0 0;
          box-shadow: -1vw -1vw 1vw rgba(0, 0, 0, 0.18);
          z-index: 0;
        }
      }
    }

    //悬浮红点
    & .app-red-dot {
      position: absolute;
      right: -1vw;
      top: 0;
      width: 0.7vw;
      height: 0.7vw;
      background-color: #e74652;
      border-radius: 50%;
    }

  }
}

//2x2属性的popover
#statusPopover {
  & .MuiPaper-root {
    //width: 17.7vw;
  }
}
