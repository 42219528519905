.transaction-interface-root {

  //大标题
  & > .trade-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.7vw;

    & > .title {
      font-size: 1.5vw;
      color: #064562;
    }

    & .type {
      font-size: 1.5vw;
      margin-right: 2vw;
      color: #064562;

    }

    & >.orange {
      color: #ed6c02;

    }
  }

  //页首
  & > .headers {
    display: flex;
    color: #5f6e7b;
    align-items: center;

    & .MuiSvgIcon-root {
      font-size: 1.1vw;
      margin-left: 0.5vw;
    }
  }


  //发起方留言
  & > .message-container {
    position: relative;
    box-sizing: border-box;
    width: 95%;
    min-height: 3vw;
    margin: 1vw auto 1.5vw auto;
    border-radius: 1vw;
    background-image: url("./assets/note.webp");
    background-size: 100% auto;
    filter: drop-shadow(0 0 0.5vw rgba(0, 0, 0, 0.18));
    -webkit-filter: drop-shadow(0 -0.3vw 0.5vw rgba(0, 0, 0, 0.18));

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -0.9vw;
      right: 2vw;
      width: 1vw;
      height: 1vw;
      margin-left: 2vw;
      background-color: #494949;
      //background-color: white;
      clip-path: polygon(0 90%, 100% 0%, 100% 100%, 0 100%,);
    }

    & .MuiTextField-root {
      margin: -0.5vw 0;

      & .MuiInput-root {
        margin: 0;
        padding: 0;

        & .MuiInput-input {
          padding-bottom: 0;
          font-weight: 700;
          color: white;
        }
      }
    }

    //view情况下的显示div
    & > .view {
      margin: 0 3vw;
      text-align: left;
      font-weight: 700;
      color: white;

    }

    & .quote {
      position: relative;
      top: 0.8vw;
      margin: 0 0.2vw;
      font-size: 2.3vw;
      font-weight: 900;
      font-family: 'Noto Serif SC', serif;
      line-height: 2.3vw;
      color: #bdbdbd;
      text-align: left;
      pointer-events: none;

      &.end {
        text-align: right;

      }
    }
  }

  //玩家名的分割线
  & .MuiDivider-root {
    margin-top: 0.7vw;
    margin-bottom: 0.7vw;

    & .MuiDivider-wrapper {
      display: flex;
      text-align: left;

      & .up {
        font-size: 1.1vw;
        color: #064562;
      }

      & .down {
        font-size: 0.75vw;
        color: #5f6e7b;
      }
    }
  }

  //主要交易面板，中间那两列
  & .txn-panels {
    display: flex;
    justify-content: space-between;
    min-height: 10vw;
    //左侧发起方面板
    & > .side {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 47.5%;

      //模式选择器：货物/建筑
      & .mode-selector-container {
        margin: -0.8vw 0 0.4vw 0;

        & .mode-selector {
          display: flex;
          align-items: center;

          & .img {
            height: 2.5vw;
            width: 2.5vw;
            margin-right: -0.8vw;
            background-size: contain;
          }

          & .mode {
            font-family: 'Noto Serif SC', serif;
            font-size: 1.1vw;
            font-weight: 700;
            color: grey;
          }
        }

        & .progress-dots-root {
        }

      }

      //最高生长对其倍数。随便一个大数就行，10以上
      --flex-grow: 20;

      //交易的是物品的情况
      & .trade-items {
        display: flex;
        flex-direction: column;
        min-height: 5vw;
        width: 100%;
        flex-grow: var(--flex-grow);

      }

      //交易的是建筑的情况
      & .trade-buildings {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 2vw;
        width: 100%;
        flex-grow: var(--flex-grow);

        //橘红按钮小修layout
        .button8 {
          margin: 0.2vw 0;
        }

        //箭头
        & .arrow-container {
          display: flex;
          flex-direction: column;
          flex-grow: var(--flex-grow);
          align-items: center;

          & .big-arrow {
            background-color: #EEEEEEFF;
          }

          & .square {
            width: 7vw;
            min-height: 4vw;
            flex-grow: var(--flex-grow);
            background-image: linear-gradient(to top, white 1vw, #EEEEEEFF 3vw);

          }

          &.flip {
            transform: rotate(180deg);
          }
        }

        //交易的建筑们
        & .buildings-packs {
          display: flex;
          flex-direction: column;
          align-items: center;

          & .button8 {
          }

          & .drop-shadow {
            //交易建筑双层框框
            & .trade-buildings-container {
              box-sizing: border-box;
              width: 100%;
              background-image: url("./assets/gold-leaf-texture-small.webp");
              background-size: 8vw;
              border-radius: 0.5vw;
              margin: 0.25vw 0;

              & .trade-buildings-container-2 {
                position: relative;
                margin: 0.5vw;
                padding: 0.3vw;
                border-radius: 0.4vw;
                box-sizing: border-box;
                background-color: white;

                & .trade-building {
                  display: flex;
                  align-items: center;

                  & .MuiIconButton-root {
                    color: #bd1f1f;
                    width: 2.5vw;
                    height: 2.5vw;
                    margin: 0 -0.2vw 0  -0.6vw;
                  }

                  & .storage-building {
                    margin: 0.2vw 0;
                    flex-grow: 2;
                  }
                }


                //物品左上角小图标
                &::after {
                  content: "";
                  display: block;
                  position: absolute;
                  width: 2.3vw;
                  height: 2.3vw;
                  left: -0.7vw;
                  top: -1.5vw;
                  background-image: url("./assets/building.webp");
                  background-size: contain;
                  pointer-events: none;
                }
              }
            }
          }
        }
      }

      & .drop-shadow {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        min-height: 0.5vw;
        border-radius: 1vw;
        filter: drop-shadow(0 0.1vw 0.4vw rgba(0, 0, 0, 0.3));
        -webkit-filter: drop-shadow(0 0.1vw 0.4vw rgba(0, 0, 0, 0.3));


        //有物品列表的drop shadow
        &.with-items {
          flex-grow: var(--flex-grow);
          display: flex;
          flex-direction: column;

          & .item-storage {
            flex-grow: var(--flex-grow);
            display: flex;
            flex-direction: column;

            & .trade-items-container {
              flex-grow: var(--flex-grow);
              display: flex;
              flex-direction: column;

              & .trade-items-container-2 {
                flex-grow: var(--flex-grow);
                display: flex;
                flex-direction: column;
                align-items: center;

                & .button8 {
                  margin: 0.5vw 0 0.3vw 0;
                }
              }
            }


          }
        }

        //交易物品的大白框，不包括箭头
        & .item-storage {
          min-height: 3vw;
          background-color: white;
          padding: 0 0;
          border-radius: 0.3vw;

          //交易物品的,物品仓库建筑信息 storage-building 在外层


          //交易物品双层框框
          & .trade-items-container {
            box-sizing: border-box;
            width: 95%;
            margin: 0.1vw auto;
            padding: 0.5vw;
            background-image: url("./assets/gold-leaf-texture-small.webp");
            background-size: 8vw;
            border-radius: 0.5vw;
            box-shadow: inset 0 0 0.3vw rgba(0, 0, 0, 0.3);

            & .trade-items-container-2 {
              position: relative;
              width: auto;
              height: auto;
              min-height: 4vw;
              padding: 0.3vw;
              box-sizing: border-box;
              background-color: white;

              //物品左上角小图标
              &::after {
                content: "";
                display: block;
                position: absolute;
                width: 2.3vw;
                height: 2.3vw;
                left: -0.7vw;
                top: -1.5vw;
                background-image: url("./assets/item.webp");
                background-size: contain;
                pointer-events: none;
              }

              & .trade-item {
                display: flex;
                align-items: center;
                margin-bottom: 0.2vw;

                & .clear {
                  color: #bd1f1f;
                  width: 2.5vw;
                  height: 2.5vw;
                  margin-left: -0.5vw;
                }

                & > .two-story-text {
                  margin: auto 0;

                  & .up {
                    font-size: 1.25vw;
                    line-height: 1vw;
                    margin: 0.4vw 0 0.2vw 0;
                  }

                  & .down {
                    text-align: left;
                    font-size: 0.8vw;
                    line-height: 0.7vw;
                    min-height: 1vw;
                  }

                  & .MuiInputBase-input {
                    //width: 2vw;
                    padding: 0 0 0 0.2vw;
                    font-size: 1vw;
                  }
                }
              }
            }
          }
        }

      }

      & .big-arrow {
        width: 100%;
        height: 2.5vw;
        margin: -0.1vw 0;
        background-color: white;
        clip-path: polygon(0 0, 100% 0, 100% 6%, 50% 60%, 0 6%);

        &.rotate {
          rotate: 180deg;
        }
      }


    }

    & > .l {
      flex: 1;
    }

    //右侧接受方面板
    & > .r {
      flex: 1;

    }

    //小蓝字
    & .text {
      text-align: left;
      color: #064562;
      font-size: 0.9vw;
      line-height: 1.15vw;
    }


  }

  & .buttons {
    margin: 2vw 10vw 0 10vw;
    display: flex;
    justify-content: center;


    & .MuiButtonBase-root  {
      margin: 0.25vw 2.5vw;
      white-space: nowrap;

    }
  }

}

