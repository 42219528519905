.cooking-method-button-root {
  position: relative;
  width: 5.1vw;
  height: 2.5vw;
  background-image: url("./assets/cookingButton2.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 0.1vw 0.4vw 0;
  filter: drop-shadow(0 0.4vw 0.2vw rgba(0, 0, 0, 0));
  -webkit-filter: drop-shadow(0 0.4vw 0.2vw rgba(0, 0, 0, 0));
  cursor: pointer;
  overflow: hidden;
  transition:filter 500ms, background-image 100ms, color 200ms;

  & img {
    position: absolute;
    left: -0.5vw;
    width: 60%;
    mask-size: 167%;
    mask-position: 0.6vw -0.1vw;
    mask-repeat: no-repeat;
    mask-image: url("./assets/cookingButton2.webp");
    filter: grayscale(1) brightness(2);
  }

  & .chs {
    font-family: 'Ma Shan Zheng', cursive;
    position: absolute;
    right: 0.8vw;
    top: 0.5vw;
    font-size: 1.5vw;
    color: white;
  }

  &.selected {
    background-image: url("./assets/cookingButton1.webp");
    filter: drop-shadow(0 0.4vw 0.2vw rgba(0, 0, 0, 0.5));
    -webkit-filter: drop-shadow(0 0.4vw 0.2vw rgba(0, 0, 0, 0.5));

    & img {
      filter: grayscale(1) brightness(0);
    }

    & .chs {
      color: black;
    }
  }

  &.disabled {
    background-image: url("./assets/cookingButton3.webp");
    filter: drop-shadow(0 0.4vw 0.2vw rgba(0, 0, 0, 0));
    -webkit-filter: drop-shadow(0 0.4vw 0.2vw rgba(0, 0, 0, 0));

    & img {
      filter: grayscale(1) brightness(0.7);
    }

    & .chs {
      color: grey;
    }
  }

}