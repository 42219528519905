.district-housing-root {
}

.leasing-bullet-render-root{
  margin-top: 0.5vw;
  & .leasing-bullet-root {
    display: flex;
    align-items: center;
    height: 3.6vw;
    padding: 0 0 0 3vw;
  }
}