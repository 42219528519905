//.p-login.captcha{
//     align-content:center; justify-content:center
//}
//.MuiTextField-root{
//    font-size: 35px !important;
//}
.p-login .captcha {
  //background-color: #75ba38;
  padding: 10px 10px 20px 10px;
  border-radius: 3px;
  border: 1px solid grey;
}

.p-login .HCaptcha-texT {
  font-size: 12px;
  text-align: center;
}
html {
  background-color: #202125;

}
.p-login {
  display: flex;
  flex-direction: row;
  background-color: #202125;
  color: #808080;
  height: max(100%, 100vh);
  font-size: 15px;

& .title{
  text-align: center;
  font-family: 'Noto Serif SC', serif;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
}

  & .left-container {
    flex-direction: row;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding-top: 160px;

    & .left {
      width: 308px;
      height: 309px;
      margin: 200px 50px;
      align-self: start;

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }

  & .login-container {
    flex: 0 0 340px;
    flex-direction: column;

    & .logo {
      //display: flex;
      flex-grow: 1;
      width: 240px;
      margin: 80px auto 0 auto;

      & img {
        width: 100%;
        height: 100%;
        margin-bottom: 10px;
      }

      & iframe {
        position: relative;
        left: -150px;
      }
    }

    & .login-form {
      flex-grow: 1;
      //display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      //margin-bottom: 40px;

      & .MuiFormControl-root {
        margin-bottom: 10px;
        height: 68px;
      }

    }

    & .form-actions {
      flex-grow: 1;
      margin-top: 50px;
      position: relative;
      //display: flex;
      //justify-content: center;

      & .submit-btn {

        font-family: 'Noto Serif SC', serif;
        font-weight: 900;
        color: #ed6c02;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 35px;
        line-height: 25px;
        letter-spacing: 6px;
        width: 197px;
        height: 65px;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #202125;
        background-image: url("./assets/login/button_black.webp");
        transition-duration: 200ms;
        outline: none;
        border: 1px solid grey;
        border-radius: 3px;
      }

      & .submit-btn:hover {
        border: none;
        color: #202125;
        background-image: url("./assets/login/big-button-background.webp");
        box-shadow: 0.2px 0 10px 0 #ed6c02;
      }

    }
  }

  & .right-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding-top: 160px;

    & .texts {
      //font-size: 15px;
      width: 300px;
      margin: 180px 50px;
      align-self: start;

      & p {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.06px;

        & strong {
          color: #bfbfbf;
          font-weight: 500;
        }
      }
    }
  }
}

.p-login .alert {
  margin-top: 20px;
  color: red;
  text-align: center;
  font-size: 15px;
}

.p-login .alert {
  margin-top: 20px;
  color: red;
  text-align: center;
  font-size: 15px;
}
