//建造模式，建筑种类选择里面那个0.6的小蓝字。附带0.2的空行
.district-overview .text {
  font-size: 0.7vw;
  line-height: 0.7vw;
  text-align: left;
  color: #064562;
  margin-bottom: 0.2vw;
}

.district-overview .flex-row {
  display: flex;
}

//BP那个输入名字的框
.district-overview .BPBName {
  margin: 0;
  padding: 0;

  & .MuiInputBase-input {
    padding: 0;
    width: 9.5vw;
    max-height: 1.25vw;
    overflow: visible;
    color: white;
    font-size: 1.25vw;
    border-color: white;
    //line-height: 0.5vw;
  }
}

//正文
.district-overview {
  //标题数据面板的container
  border-top-right-radius: 1vw;
  transition-duration: 300ms;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("./assets/BPModeBGBlank.webp");


  & .blueprint-mode {
    position: absolute;
    top: 3vw;
    right: 3vw;
    width: 2.2vw;
    height: 2.2vw;
    border-radius: 0.2vw;
    border: 0.15vw solid #717171;
    cursor: pointer;
    background-image: url("../../../assets/login/logo_cn.webp");
    transition: background-image 100ms, border 100ms, box-shadow 100ms;
    background-size: cover;
    box-shadow: 0.02vw 0 1vw 0 rgba(0, 0, 0, 0);

    & .blueprint-icon {
      position: absolute;
      top: 25%;
      left: 25%;
      font-size: 1.2vw;
      margin: auto auto;
      color: #717171;
    }
  }

  & .blueprint-mode.BPMode {
    background-image: url("../../../assets/login/big-button-background.webp");
    border: 0.15vw solid white;
    box-shadow: 0.02vw 0 1vw 0 #fd7700;

    & .blueprint-icon {
      color: black;
    }
  }

  & .blueprint-mode:hover {
    border: 0.15vw solid white;
    background-image: url("./assets/blueprint.webp");
    box-shadow: 0.02vw 0 1vw 0 #064562;

    & .blueprint-icon {
      color: white;
    }
  }

  & .sum {
    font-size: 0.85vw;
    color: #596a76;
    padding-top: 2.5vw;
  }

  & .sum.BPMode {
    color: white;
  }

  & .big-numbers {
    font-size: 3.1vw;
    line-height: 3.1vw;
    color: black;
    font-family: 'Noto Serif SC', serif;
    font-weight: 900;
    -webkit-filter: drop-shadow(0 0.2vw 0.2vw rgba(0, 0, 0, 0.4));
    filter: drop-shadow(0 0.2vw 0.2vw rgba(0, 0, 0, 0.4));
    margin-bottom: 0.3vw;
  }

  & .big-numbers.BPMode {
    color: white;
  }

  & .daily-data-line {
    display: flex;
    flex-direction: row;
    //align-items: center;
    justify-content: center;

    & .daily-data-block {
      margin: 0.4vw 0;
      min-width: 5.7vw;

      & .float {
        font-weight: 700;

        //buff/debuff的那个颜色字
        &.red {
          color: #bd1f1f;
        }

        &.green {
          color: #75ba38;
        }

      }

      & .number {
        font-size: 2vw;
        line-height: 1.4vw;
        font-weight: 500;
      }

      & .title {
        font-size: 0.9vw;
      }
    }
  }

  & .tera-sum {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 0.8vw;
    line-height: 1vw;
    font-size: 1vw;
    color: #596a76;

  }

  & .citizen-sum {
    font-size: 1vw;
    color: #596a76;

  }

  //}

  //}

  //城区或者郊区的部分
  & .location {
    margin-bottom: 5vw;

    & .city-districts-banner {
      //background: #75ba38;
      margin: 3vw 0 7.5vw 0;

      & .sub-title {
        font-size: 1.5vw;
        color: black;
        opacity: 0.75;
        font-family: 'Noto Serif SC', serif;
        font-weight: 900;
      }

      & img {
        position: absolute;
        transform: translateY(-42%);
        left: 0;
        width: 100%;

      }
    }


    //单个街区列表
    & .district {
      position: relative;
      z-index: 3;
      width: 52.8vw;
      margin: 0 auto 2vw auto;
      border-radius: 0.5vw;
      box-shadow: 0 0.1vw 1.2vw rgba(0, 0, 0, 0.6);

      & .banner {
        border-radius: 0.5vw 0.5vw 0 0;
        height: 7.2vw;
        background-image: url("assets/marble-texture.webp");
        background-size: contain;
        box-shadow: 0 0.5vw 0.7vw -0.5vw rgba(51, 25, 0, 0.5);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        //justify-content: center;
        cursor: pointer;

        & .left {
          display: flex;
          flex-direction: row;

          & .dot {
            position: relative;
            z-index: 6;
            margin: auto 1vw;
            width: 0.4vw;
            height: 0.4vw;
            background: lightgrey;
            box-shadow: 0 0.1vw 0.7vw rgba(0, 0, 0, 0.6);
            border: white solid 0.17vw;
            //align-self: center;
          }

          & .avatar {
            position: relative;
            z-index: 6;
            box-shadow: 0 0.1vw 0.7vw rgba(0, 0, 0, 0.6);
            border-radius: 0.2vw;
            border: white solid 0.17vw;
          }

          & .name-column {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            //justify-content: center;

            & .location {
              position: absolute;
              top: 0;
              font-size: 0.6vw;
              color: #596a76;
              margin-left: 0.5vw;
            }

            & .name {
              height: 2.9vw;
              width: 13.8vw;
              background-image: url("assets/CS_district_banner.webp");
              background-size: contain;
              background-repeat: no-repeat;
              margin: 1.1vw 0 0 -8.8vw;
              padding: 0 0 0 9.2vw;
              text-align: left;
              font-size: 1.7vw;
              line-height: 2.7vw;
              color: white;
              font-family: 'Noto Serif SC', serif;
              font-weight: 900;
              -webkit-filter: drop-shadow(0 0.2vw 0.15vw rgba(0, 0, 0, 0.4));
              filter: drop-shadow(0 0.2vw 0.15vw rgba(0, 0, 0, 0.4));
            }
          }

          & .attributes {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 0.2vw;

            & .attribute {
              display: flex;
              margin-bottom: 0vw;


              & .text {
                background-color: #9c7732;
                color: white;
                font-size: 0.7vw;
                line-height: 0.8vw;
                padding: 0 0.3vw;
              }

              & .progress-block {
                background-color: #9c7732;
                margin: 0 0.12vw;
                height: 0.8vw;
              }

              & .percentage {
                color: #9c7732;
                font-size: 0.9vw;
                line-height: 0.7vw;
                font-weight: 500;
              }
            }

            & .sanitation {

            }

            & .prosperity {

            }

            & .industrialisation {

            }
          }
        }

        & .right {
          display: flex;
          margin-right: 2vw;

          & .land-n-tera {
            display: flex;
            flex-direction: column;
            justify-content: center;

            & .land {
              font-size: 1.6vw;
              line-height: 1.7vw;
              font-family: 'Noto Serif SC', serif;
              font-weight: 900;
            }

            & .tera {
              color: #5a6a77;
              display: flex;
              justify-content: flex-end;
            }
          }

          & .divider {
            width: 0.25vw;
            height: 2.9vw;
            background-color: #5a6a77;
            margin: auto 1.2vw auto 0.7vw;
          }

          & .visit {
            margin: auto 0;
            max-width: 2.8vw;
            color: #9c7732;
            font-family: 'Noto Serif SC', serif;
            line-height: 1.6vw;
            font-size: 1.3vw;
            font-weight: 700;
          }

          & .visit-icon {
            & .icon {
              position: relative;
              top: 0.2vw;
              margin: auto auto;
              color: #9c7732;
              //transform: rotate(-90deg);
              font-size: 3.4vw;

            }

          }
        }
      }

      & .list {
        //height: 5vw;
        width: 100%;

      }

      & .list-footnote {
        justify-content: center;
        color: #5a6a77;
        font-size: 0.7vw;
      }

      //给“没有建筑，尚未开发的街区。”撑高度的
      & .no-building-note {
        height: 3.5vw;
      }
    }
  }
}


.district-overview.BPMode {
  background-image: url("./assets/BPModeBG.webp");
}

.district-overview .build-preview-bar {
  width: 39vw;
  max-height: 2.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 0.15vw dashed white;
  border-radius: 0.35vw;
  padding: 0.2vw 0.5vw;
  margin: 1vw auto 0 auto;
  position: sticky;
  top: 3vw;
  background-image: url("./assets/BPModeBG.webp");
  background-size: 130% 600%;
  background-position: center;
  z-index: 50;

  & .side {
    display: flex;
    flex-direction: row;

    //顶上预览条的文字方块，可以点亮
    & .two-story-text {
      margin: auto 0.2vw;
      text-align: left;
      min-width: 5.2vw;
      max-width: 5.2vw;
      border-radius: 0.2vw;
      padding: 0.15vw 0.15vw;
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 200ms;
      //transition-duration: 300ms;

      &.left {
        min-width: 11vw;
        max-width: 11vw;

        & .up {
          max-height: 1.3vw;
        }
      }

      & .up {
        font-size: 1.25vw;
        line-height: 1.2vw;
        margin: 0 0 0.05vw 0;
        color: white;
        white-space: nowrap;
        overflow: hidden;
      }

      & .down {
        font-size: 0.7vw;
        color: white;
      }
    }

    & .two-story-text.lit {
      background-color: white;

      & .up {
        color: #064562;
      }

      & .down {
        color: #064562;
      }
    }

    & .start-building {
      margin-left: 0.2vw;
      padding-left: 0.7vw;
      min-width: 6.5vw;
      max-width: 6.5vw;
      color: white;
      opacity: 0.7;
      font-weight: 700;
      font-size: 1.2vw;
      line-height: 2.5vw;
      text-align: center;
      vertical-align: middle;
      border-radius: 0.2vw;
      cursor: pointer;
    }

    & .CircularProgress {
      margin: 0.5vw auto;
    }

    & .start-building.lit {
      color: #064562;
      background-color: white;
      opacity: 1;
      box-shadow: 0.02vw 0 1vw 0 white;
    }
  }
}

//BP模式确认选择submit按钮
.district-overview .submit {
  position: absolute;
  width: 2vw;
  height: 2vw;
  border-radius: 0.3vw;
  border: 0.2vw solid #064562;
  right: .5vw;
  top: 3.3vw;
  font-size: 1.5vw;
  font-weight: 500;
  color: #064562;
  cursor: pointer;
  background-color: white;
  transition: background-color 100ms, color 100ms;

  &.terra {
    position: relative;
    top: 0;
    right: 0;
    margin: auto 0 auto 2vw;
    text-align: center;
  }

  &.selected {
    color: white;
    background-color: #064562;
  }

  &:hover {
    background-color: #064562;
    //background-color: rgba(2, 27, 38, 0.2);
    color: white;
  }
}