.building-agent-root {
  position: relative;
  width: 100%;
  height: 100%;

  & .building-function-title {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 1.4vw;
    font-family: 'Noto Serif SC', serif;
    font-weight: 900;
    //margin-left: 4vw;
  }

  & .agent-infos {
    display: flex;
    justify-content: space-between;
    margin: 0 2vw;

    & .tax-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Noto Serif SC', serif;
      font-weight: 900;
      margin-left: 4vw;
      color: #9c7732;

      & .tax {
        font-size: 3vw;
        line-height: 3vw;
      }

      & .description {

      }
    }

    & .history-data {
      text-align: right;
      font-size: 0.7vw;
      color: #5f6e7b;
    }
  }

  & .filters-container {
    display: flex;
    justify-content: space-between;
    margin: 2.5vw 2vw 0.5vw 2vw;
    color: #5f6e7b;
    font-size: 0.8vw;
    //font-family: 'Noto Serif SC', serif;
    & .title {

    }

    & .description {

    }
  }
}