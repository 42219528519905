/* autoprefixer grid: autoplace */

.c-square .makespeech-avt {
  width: 3vw;
  height: 3vw;
}

.c-square {
  display: flex;
  overflow-y: scroll;
  overflow-x: visible;
  height: 95vh;
  position: relative;
  padding-left: 2vw;
}

.c-square::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.c-square .left-container {
  flex: 1.6;
}

.c-square .left-container .header-img-container img {
  width: 100%;
}

.c-square .left-container .artworks-img-container {
  position: relative;
  margin-bottom: 4vw;
  /*height: 3vw;*/
}

.c-square .left-container .artworks-img-container .hot-tweet-container-text {
  position: relative;
  top: -10.2vw;

}

.c-square .left-container .artworks-img-container img {
  position: absolute;
  left: -5%;
  width: 110%;
  top: -8.5vw;
}

.c-square .left-container .hot-tweet-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.hot-tweet-container-text {
  font-size: 1.5vw;
  color: black;
  opacity: 0.75;
  font-family: 'Noto Serif SC', serif;
  font-weight: 900;
}

.c-square .left-container .hot-tweet-inner-container {
  margin-top: 0.6vw;
  padding-bottom: 4vw;
}

.c-square .left-container .tweet-card {
  display: flex;
  flex-direction: column;
  /*padding: 2vw 1.2vw;*/
  box-shadow: 0.02vw 1vw 2.5vw 0 rgba(38, 31, 17, 0.3);
  border-radius: 1vw;
  max-width: 53vw;
  margin: 0 6vw;
}

.c-square .left-container .crowd-img-container {
  position: relative;
  padding-bottom: 1vw;
  margin-top: 3vw;
  pointer-events: none;
  top: -2vw;
}

/*.c-square .left-container .crowd-img-container.system-speech-crowd{*/
/*    margin-top: 6vw;*/
/*}*/
.c-square .hot-tweet-container .system-speech-expand {
  position: relative;
  /*height: 1vw;*/
  top: -3vw;
  /*left: 2vw;*/
  /*z-index: 4;*/
}

.c-square .left-container .system-speech-divider {
  margin-bottom: 3.5vw;
}

.c-square .left-container .crowd-img-container img {
  position: absolute;
  transform: translateY(-82%);
  left: 0;
  width: 100%;
}


.c-square .right-container {
  flex: 1;

  //写演讲卡片
  & .writing-speech {
    max-width: 30vw;
    border-radius: 1vw;
    margin: 1vw;
    box-shadow: 0 1vw 2vw -1.5vw rgba(0, 0, 0, 0.6);

    & .actions {
      display: flex;
      align-items: center;
      height: 2.5vw;
      padding: 0.5vw 3vw 0.5vw 2.5vw;

      & .description {
        margin-right: auto;
        width: 8vw;
        color: rgba(0,0,0,0.38) ;
        font-size: 0.8vw;
        text-align: left;
      }

      & .MuiButton-root {
        width: 7vw;
        font-weight: 900;
        font-size: 0.9vw;
        margin: 0 -2.5vw 0 0.25vw;
        padding: 0.5vw 0;

        & .MuiBox-root {
          margin-left: 0.5vw;
        }
      }
    }
  }
}


.local-speech-container-text {
  font-size: 1.5vw;
  color: black;
  opacity: 0.75;
  font-family: 'Noto Serif SC', serif;
  font-weight: 900;
  text-align: left;
  margin: 0.5vw 2vw 0 2vw;
}


.speechtextmidiumblock {
  min-height: 0;
  height: 1.5vw;
}

.c-square .MuiButtonGroup-grouped {
  min-width: 0 !important;
}