.working-results-modal-root {
  & .working-results-root {
    width: 35vw;
    min-height: 16vw;
    max-height: 100vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 0.6vw;
    outline: none;
    //padding为了抵消标题高度
    padding-top: 3.5vw;
    transition: height 300ms;

    & .title-image {
      position: absolute;
      pointer-events: none;
      background-size: contain;
      background-repeat: no-repeat;

      &.title-image1 {
        top: -2vw;
        left: 4vw;
        width: 30vw;
        height: 11vw;
        background-image: url("./assets/1.webp");


      }

      &.title-image2 {
        top: -4.8vw;
        left: -7vw;
        width: 18vw;
        height: 11vw;
        background-image: url("./assets/wage.webp");
        transform: rotate(130deg);
        display: flex;

        & .MuiSvgIcon-root {
          transform: rotate(-130deg);
          position: relative;
          left: 37%;
          top: 25%;
          font-size: 3vw;
          //color: #9c7732;
          filter: drop-shadow(0 0.2vw 0.2vw #9c7732);
          -webkit-filter: drop-shadow(0 0.2vw 0.2vw #9c7732);
        }
      }
    }

    & .title {
      position: absolute;
      top: -0.2vw;
      left: 9vw;
      font-size: 1.5vw;
      font-family: 'Noto Serif SC', serif;
      font-weight: 900;
    }

    & .working-results-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      &.page0 {
        & .status-Characteristics-container {
          display: flex;
          align-items: center;
          margin: 1vw 0;

          & .status {
            flex: 1;
            display: flex;
            align-items: center;
            margin: 0 2vw;

            & .status-bar1x4 {
              width: 8vw;
              margin-left: 0.4vw;
            }

            & .text {
              text-align: center;
            }
          }

          & .characteristics {
            flex: 1;
            margin: 0 2vw;
            font-size: 0.8vw;
            color: #5f6e7b;

          }
        }

        & .skills-container {
          display: flex;
          justify-content: center;
          margin: 1vw 0 0.5vw 0;

          & .skill {
            display: flex;
            align-items: center;
            margin: 0 1vw;

            & .value-info {
              margin-left: 0.5vw;

              & .value {
                font-size: 2vw;
                line-height: 2.2vw;
                font-weight: 500;
                //color: #75ba38;
              }

              & .name {
                font-size: 0.9vw;
              }
            }
          }
        }

        & .help-text {
          font-size: 0.8vw;
          color: #5f6e7b;
          margin: 0.2vw 0;

        }

        & .orange-button {
          margin-top: 1.5vw;
        }

      }

      &.page1 {
        & .skills-capacity-container {
          display: flex;
          align-items: center;
          margin: 0.7vw 0;

          & .skills {
            display: flex;
            width: 19vw;
            align-items: center;

            & .text {
              margin: 0 1vw;
            }

            & .skill {
              display: flex;
              align-items: center;
              //margin: 0 1vw 0 0;

            }
          }
        }

        & .buffs-container {
          display: flex;
          align-items: center;
          margin: 0.7vw 0;

          & .buffs {
            width: 19vw;
          }
        }

        & .output-container {
          margin: 0.7vw 0;
          width: 100%;
          min-height: 4vw;
          display: flex;
          flex-direction: column;
          align-items: center;

          & .sub-title {
            position: relative;
            left: -10.3vw;
          }

          & .item {
            display: flex;
            align-items: center;
            margin: 0.5vw 0;

            & .text {
              margin-left: 0.5vw;
              font-size: 1.8vw;
              font-weight: 700;
            }
          }

          & .description {
            color: #5f6e7b;
            font-size: 0.8vw;
          }

          & .method-bullet-component {
            margin-top: 1vw;
          }
        }
      }

      &.page2 {
        & .salary-container {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0.7vw 0 0.5vw 0;
          width: 21vw;
          height: 5vw;
          border-radius: 0.3vw;
          box-shadow: 0 0.4vw 1vw rgba(0, 0, 0, 0.6);
          border: 0.3vw dashed #75ba38;
          outline: 0.5vw solid white;

          & .item {
            display: flex;
            align-items: center;
            justify-content: center;

            & .text {
              margin-left: 0.5vw;
              font-size: 1.8vw;
              font-weight: 700;
            }
          }
        }

        & .description {
          font-size: 0.8vw;
          color: #5f6e7b;
          margin: 0.5vw 0;
        }
      }

      //额外style
      & .sub-title {
        font-family: 'Noto Serif SC', serif;
        font-weight: 700;
        color: #5f6e7b;
      }

      & .value-info {
        margin-left: 0.5vw;
        //flex:10vw;

        & .value {
          font-size: 1.8vw;
          line-height: 2.2vw;
          //font-weight: 500;
          white-space: nowrap;
        }

        & .name {
          font-size: 0.9vw;
          white-space: nowrap;
        }
      }

      & .orange-button {
        margin: 1vw 0 1vw 0;
        padding: 0.3vw;
        white-space: nowrap;
        width: auto;
      }
    }

    & .progress-dots-root {
      margin-bottom: 1.5vw;
    }
  }
}