.residence-root {
  & .residence-title {
    display: inline-block;
    //max-width: 5vw;
    font-size: 1.4vw;
    bottom: 0;
    font-family: 'Noto Serif SC', serif;
    font-weight: 900;
    position: relative;
    left: 4vw;
  }

  & .residence-content {
    background-image: url("./assets/bg.webp");
    background-size: 38vw;
    background-repeat: no-repeat;
    background-position: 50% 3.5vw;
    padding-top: 5vw;
    height: calc(100vh - 5vw);

    & .residence-card {
      position: relative;
      max-width: 15.2vw;
      border-radius: 0.2vw;
      margin: 0 auto;
      background-color: white;
      box-shadow: 0 0.3vw 0.5vw rgba(0, 0, 0, 0.5);
      padding: 1vw 1vw;

      & .title-img {
        position: absolute;
        top: -4.5vw;
        object-fit: cover;
        object-position: 0 80%;
        width: 22vw;
        height: 7vw;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
      }

      & .title-banner {
        display: flex;
        position: relative;
        left: -1.8vw;
        width: 16.6vw;
        height: 3vw;
        margin: 0 auto;
        background-image: url("./assets/title.webp");
        background-size: contain;
        background-repeat: no-repeat;
        color: white;
        -webkit-filter: drop-shadow(0 0.3vw 0.2vw rgba(0, 0, 0, 0.3));
        filter: drop-shadow(0 0.3vw 0.2vw rgba(0, 0, 0, 0.3));
        padding: 0.8vw 1vw;
        text-decoration: none;

        & .avt {
          margin-left: 1.6vw;
          margin-right: 0.6vw;
        }

        & .r {
          & :nth-child(1) {
            font-weight: 700;
            line-height: 1.6vw;
          }

          & :nth-child(2) {
            font-size: 0.7vw;
          }
        }
      }

      & .description {
        text-align: center;
        font-size: 0.6vw;
        line-height: 0.9vw;
        margin: 0.3vw auto 0.5vw auto;

        & .selector {
          color: #5f6e7b;
        }
      }

      & .status {
        margin: 0.6vw 0;
        font-size: 0.8vw;

        & .sub-title {
          font-size: 0.6vw;
          color: #5f6e7b;
        }

        & .black-text {
          margin-left: 1vw;
          font-weight: 700;
        }
      }
    }

    & .hint {
      text-align: center;
      font-size: 0.6vw;
      line-height: 0.9vw;
      margin: 1vw auto;

    }
  }
  //属性svg
  & .type-icon {
    height: 0.8vw;
    width: 0.8vw;
    display: inline-block;
    position: relative;
    margin-right: 0.1vw;
    top:0.1vw;
  }
  .black {
    color: black;
    font-weight: 700;
  }
  .energy{
    color: #46b4e6;
  }
  .happiness{
    color: #ff8842;
  }
  .health{
    color: #73b93c;
  }
  .hungry{
    color: #e74652;
  }
}