.bazaar-items-view-root {
  margin: 0.5vw 1vw 0 0;
  //overflow: auto;

  & .bazaar-item-bullet-root {
    position: relative;

    & .bazaar-item-seller-and-others {
      display: flex;
      width: 100%;
      align-items: center;
    }

    & .MuiTextField-root {
      width: 6vw;
      margin-left: 0.5vw;
    }

  }
}



