.bazaar-root {
  height: calc(100vh - 5vw);
  position: relative;
  padding-left: 2vw;
  display: flex;

  & .left-container {
    flex: 1;
    background-image: url("assets/bazaar-bg.webp");
    background-size: 100% auto;
    background-repeat: no-repeat;
    border-top-left-radius: 1vw;
    box-shadow: inset -1vw 0 0.6vw -0.6vw rgba(0, 0, 0, 0.15);

    & .left-menu-container {
      margin-top: 2vw;
      position: relative;
      z-index: 5;
      margin-left: 2vw;
    }
  }


  & .right-container {
    flex: 3.68;
    position: relative;

  }




  & .MuiListItemButton-root {
    padding: 0.1vw 1vw;
  }

}