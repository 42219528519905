.filters-container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 3vw 0 3vw;
  height: 6vw;


  & .filters {
    display: flex;
    align-items: center;

    & .MuiFormControl-root {
      margin: 0 0.5vw;
      width: 8vw;
    }

    & > .MuiSvgIcon-root {
      color: grey;
    }


  }

  & .middle-divider.MuiDivider-vertical.MuiDivider-middle{
    margin: 2.5vw 0;
    height: auto;
  }
}