//.district-recruit-root .selectors-container {
//  margin: 2.3vw 3vw 1.3vw 3vw;
//  height: 2.3vw;
//}
.district-recruit-root {

  & .filters {
    margin-right: 7.8vw;
    margin-bottom: 0.2vw;
    display: flex;
    justify-content: flex-end;
    & .filter{
      color: #596a76;
      font-size: 0.8vw;
      width: 5.1vw;
    }
  }

  & .selectors-container {
    margin: 2.3vw 3vw 1.3vw 3vw;
    height: 2.3vw;
  }

  & .selectors-container-container {
    float: left;
  }

  & .RC-bullet-container {
    margin: 0.2vw 0.8vw;
    padding: 0.45vw 0.4vw;
    &.MuiListItemButton-root{

    }
  }
}