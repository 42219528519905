.pick-player-list-root {
  width: 23vw;
  height: 30vw;

  & .title {
    text-align: center;
    font-size: 0.8vw;
    color: #5f6e7b;
    margin: 0.1vw 0;
  }

  & .list {
    & .row {
      display: flex;
      justify-content: center;
      margin: 0.7vw 0;
      align-items: center;
      cursor: pointer;

      & .player-list-player {
        display: flex;
      }

    }
  }
}