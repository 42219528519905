.status-tutorial-root {
  padding: 0.5vw;
  width: 21vw;
  max-height: 40vw;
  display: flex;
  flex-direction: column;
  font-size: 0.8vw;
  overflow: auto;
  //-ms-overflow-style: none; /* IE and Edge */
  //scrollbar-width: none; /* Firefox */
  //&::-webkit-scrollbar {
  //  display: none;
  //}

  & .MuiDivider-root {
    margin: 1vw 0 0.5vw 0;
    font-weight: 700;
  }

  & .status-bar {
    margin: 0 auto;
  }

  & .status-polar {
    display: flex;
    justify-content: space-between;
    margin: 0 1.8vw;
    font-size: 0.7vw;
    color: #5f6e7b;
  }

  //属性影响
  & .effect {
    margin-left: 3vw;

    & .MuiSvgIcon-root {
      font-size: 0.8vw;
    }

    &.disabled {
      color: rgba(0, 0, 0, 0.38);
    }

    &.warning {
      color: #e74652;
      font-weight: 700;
    }
  }

  //换日恢复
  & .day-change {
    display: flex;
    align-items: center;
    min-height: 2.5vw;
    margin: 0.3vw 0 0.3vw 1.3vw;

    & .MuiSvgIcon-root {
      //margin-right: 0.3vw;
      color: #5f6e7b;
    }

    & .MuiDivider-root {
      border-right: 0.1vw solid rgba(0, 0, 0, 0.12);
      margin: 0vw 0.3vw;
    }

    & .changes {

      & .change {
        &.line-through {
          text-decoration: line-through;
          color: rgba(0, 0, 0, 0.38);
        }

        & b {
        }
      }
    }
  }

  & .description {
    font-size: 0.8vw;
  }
}