.facility {
  & .work-title {
    display: inline-block;
    max-width: 5vw;
    font-size: 1.4vw;
    bottom: 0;
    font-family: 'Noto Serif SC', serif;
    font-weight: 900;
    position: relative;
    left: 4vw;
  }

  & .work-status {
    text-align: center;
    width: 100%;
    height: 100%;
  }

  & .work {
    height: 50vh;
  }

  & .grid-container {
    position: relative;
    top: 3vw;
  }

}